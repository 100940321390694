import Nexus from 'nexusui';
import React from 'react';
import { NO_OP, getId } from '../../utils/index';

const MyButton = React.memo(function Button({
  size,
  mode = 'button',
  state,
  onChange = NO_OP,
  onReady = NO_OP,
}) {
  const button = React.useRef(null);
  const elementId = React.useRef(`nexus-ui-button-${getId()}`);
  React.useEffect(() => {
    button.current = new Nexus.Button(elementId.current, {
      size,
      state,
      mode,
    });
    onReady(button.current);
    button.current.on('change', (newState) => {
      onChange(newState);
    });
    return () => {
      button.current.destroy();
    };
  }, [size, state, mode, onChange, onReady]);
  return React.createElement('div', { id: elementId.current });
});

export default MyButton;
