import * as Tone from 'tone';
import assetPreloader from '../../../globals/assetPreloader';
import { transitionManifest } from '../../../globals/transitionManifest';
import prng from '../../PRNG';

export const LOADING_MUSIC_VOL = -18;
export default class LoadingMusic {
  player?: Tone.Player;

  constructor() {
    const transition =
      transitionManifest[Math.floor(prng.float() * transitionManifest.length)];
    if (!transition) return;
    this.player = assetPreloader
      .getPlayer(transition)
      .set({
        volume: LOADING_MUSIC_VOL,
        loop: true,
      })
      .toDestination();
  }

  start = () => {
    if (!this.player) return;
    try {
      if (this.player.loaded) {
        this.player.start();
      } else {
        this.player.autostart = true;
      }
    } catch (e) {
      console.log(e);
    }
  };
  stopAndDispose = () => {
    if (!this.player) return;
    this.player.volume.rampTo(-100, 0.5);
    setTimeout(() => {
      this.player?.dispose();
    }, 500);
  };
}
