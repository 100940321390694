import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import LoadingMusic, {
  LOADING_MUSIC_VOL,
} from '../../classes/changeScopes/song/LoadingMusic';
import { AppEventHandler } from '../../types/events';
import { ContextAppState } from '../ContextAppState';

interface ContextLoadingMusicValue {
  onAppEvent: AppEventHandler;
}

export const ContextLoadingMusic = createContext<ContextLoadingMusicValue>({
  onAppEvent: () => {
    return;
  },
});

const ProviderContextLoadingMusic: React.FunctionComponent<{}> = (
  props: React.PropsWithChildren<{}>,
) => {
  const [ctrl, setCtrl] = useState<LoadingMusic | null>(null);

  const {
    state: { songManifest, isLoading, muted },
  } = useContext(ContextAppState);

  useEffect(() => {
    if (!songManifest) return;
    const isLoadingSong = songManifest && isLoading;
    let controller: LoadingMusic | undefined;

    if (isLoadingSong) {
      controller = new LoadingMusic();
      controller.start();
      setCtrl(controller);
    }
    return () => {
      if (!controller) return;
      controller.stopAndDispose();
      setCtrl(null);
    };
  }, [isLoading, songManifest]);

  useEffect(() => {
    if (!ctrl || !ctrl.player) return;
    ctrl.player.set({
      mute: muted,
    });
  }, [ctrl, muted]);

  const onAppEvent: AppEventHandler = useCallback(
    (event) => {
      switch (event.name) {
        case 'mute':
        case 'openVideo':
        case 'tabBlur':
        case 'openAbout':
          if (ctrl && ctrl.player) {
            ctrl.player.volume.rampTo(-60, 0.05);
            setTimeout(() => {
              ctrl.player?.set({ mute: true });
            }, 100);
          }
          return;
        case 'unmute':
        case 'closeVideo':
        case 'tabFocus':
        case 'closeAbout':
          if (ctrl && ctrl.player) {
            ctrl.player.set({ mute: false });
            ctrl.player.volume.rampTo(LOADING_MUSIC_VOL, 0.05);
          }
          return;
      }
    },
    [ctrl],
  );

  const contextValue: ContextLoadingMusicValue = useMemo(() => {
    return {
      onAppEvent,
    };
  }, [onAppEvent]);
  return (
    <ContextLoadingMusic.Provider value={contextValue}>
      {props.children}
    </ContextLoadingMusic.Provider>
  );
};

export default ProviderContextLoadingMusic;
