import React from 'react';
import C from '../../globals/constants';
import { InnerProps } from '.';
import './style.css';

export const Squares = ({ width, height, accentColor, values }: InnerProps) => {
  const meterWidth = ((width - C.BOX_MARGIN) / 2) * 0.95;
  const meterHeight = ((height - C.BOX_MARGIN) / 2) * 0.95;
  return (
    <>
      <div
        className="MeterThingSquare"
        style={{
          height: meterHeight,
          width: meterWidth,
          marginRight: meterWidth * 0.1,
          marginBottom: meterWidth * 0.1,

          background: accentColor,
          opacity: Math.max(0.05, (values[0] + 55) / 50),
        }}
      />
      <div
        className="MeterThingSquare"
        style={{
          height: meterHeight,
          width: meterWidth,
          marginBottom: meterWidth * 0.1,
          background: accentColor,
          opacity: Math.max(0.05, (values[1] + 55) / 50),
        }}
      />
      <div
        className="MeterThingSquare"
        style={{
          height: meterHeight,
          width: meterWidth,
          marginRight: meterWidth * 0.1,
          background: accentColor,
          opacity: Math.max(0.05, (values[2] + 55) / 50),
        }}
      />
      <div
        className="MeterThingSquare"
        style={{
          height: meterHeight,
          width: meterWidth,
          background: accentColor,
          opacity: Math.max(0.05, (values[3] + 55) / 50),
        }}
      />
    </>
  );
};
