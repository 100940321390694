import { useEffect, useState } from 'react';
import {
  MultiSliderValue,
  ScalarListParam,
} from '../classes/changeScopes/types';
import { useParamContext } from './useParamContext';

export const useScalarListWidgetParam = () => {
  const [values, setValues] = useState<number[]>([0, 0, 0, 0, 0]);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(1);
  const [onChange, setOnChange] = useState<
    ((v: MultiSliderValue) => void) | undefined
  >();

  const { state, pubSub } = useParamContext();

  useEffect(() => {
    if (!state || !pubSub) return;

    const param = state.getRandomParamOfType('scalarList', pubSub) as
      | ScalarListParam
      | undefined;
    if (!param) return console.warn('couldnt get params');
    // subscribe to changes
    const subscriptionId = pubSub.subscribe(param.name, (newValue) =>
      setValues(newValue as number[]),
    );
    // set initial values
    setMin(param.min);
    setMax(param.max);
    setValues(param.val);
    // publish changes from Widget to other subscribers
    const onChange = (v: MultiSliderValue) =>
      pubSub.publish({
        paramName: param.name,
        value: v,
      });

    setOnChange(() => onChange);

    return () => pubSub.unsubscribe(subscriptionId);
  }, [pubSub, state]);

  return {
    values,
    max,
    min,
    onChange,
  };
};
