import { useEffect } from 'react';
import * as Tone from 'tone';
import useIsMounted from './useIsMounted';
import { useParamContext } from './useParamContext';

export const useRandomMeter = (onChangeValue: (v: number) => void) => {
  const { songCtrl } = useParamContext();
  const isMounted = useIsMounted();

  useEffect(() => {
    if (!songCtrl) return;
    const m = new Tone.Meter({
      smoothing: 0.2,
    });

    const nodes = songCtrl.getRandomAudioNodes(1);
    const node = nodes[0];
    if (node) {
      node.connect(m);
    }

    const update = () => {
      if (isMounted.current) {
        onChangeValue(m.getValue() as number);
      }
    };
    const rAFUpdate = () => {
      requestAnimationFrame(update);
    };
    const intervalId = window.setInterval(rAFUpdate, 100);

    return () => {
      window.clearInterval(intervalId);
      try {
        m.dispose();
      } catch (e) {
        console.error('ERR UNMOUNTING NODE', e);
      }
    };
  }, [isMounted, onChangeValue, songCtrl]);
};
