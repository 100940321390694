import { shuffleArray } from '../utils';
import { WidgetName } from '../widgets';
import G from './globals';
import SETTINGS from './settings';

const everythingBut = (widgets: WidgetName[]): WidgetName[] => {
  const allNames: WidgetName[] = [
    'Button',
    'BigDial',
    'Dials',
    'Image',
    'MultiSlider',
    'MeterThing',
    'Position',
    'Sequencer',
    'Sliders',
    'Text',
    'BeatVisual',
    'PlaceHolder',
    'TriggerButton',
    'SwitchSong',
  ];
  return allNames.filter((w) => !widgets.includes(w));
};
interface SongSetting {
  LOOPS: number;
  ONE_SHOTS: number;
  MAX_CLIPS_PER_LOOP: number;
  RESET_MS_MIN: number;
  RESET_MS_MAX: number;
  BLOCK_WIDGETS?: WidgetName[];
}

export const initialSongSetting: SongSetting = {
  LOOPS: 2,
  MAX_CLIPS_PER_LOOP: 2,
  ONE_SHOTS: 3,
  RESET_MS_MIN: 4000,
  RESET_MS_MAX: 12000,
  BLOCK_WIDGETS: ['Button', 'Dials', 'BeatVisual'],
};
let songSettings: SongSetting[] = [
  initialSongSetting,
  {
    LOOPS: 1,
    ONE_SHOTS: 5,
    MAX_CLIPS_PER_LOOP: 3,
    RESET_MS_MIN: 6000,
    RESET_MS_MAX: 6100,
    BLOCK_WIDGETS: ['Image', 'BigDial', 'Sliders'],
  },
  {
    LOOPS: 2,
    ONE_SHOTS: 0,
    MAX_CLIPS_PER_LOOP: 3,
    RESET_MS_MIN: 2000,
    RESET_MS_MAX: 12000,
    BLOCK_WIDGETS: ['Sequencer', 'BeatVisual'],
  },
  {
    LOOPS: 3,
    ONE_SHOTS: 1,
    MAX_CLIPS_PER_LOOP: 2,
    RESET_MS_MIN: 2000,
    RESET_MS_MAX: 8000,
    BLOCK_WIDGETS: ['Sliders', 'Dials', 'Image', 'MeterThing'],
  },
  {
    ...initialSongSetting,
    BLOCK_WIDGETS: [],
  },
  {
    LOOPS: 0,
    ONE_SHOTS: 6,
    MAX_CLIPS_PER_LOOP: 3,
    RESET_MS_MIN: 3000,
    RESET_MS_MAX: 12000,
    BLOCK_WIDGETS: ['MultiSlider', 'BeatVisual'],
  },
  {
    LOOPS: 3,
    ONE_SHOTS: 0,
    MAX_CLIPS_PER_LOOP: 4,
    RESET_MS_MIN: 3000,
    RESET_MS_MAX: 6000,
    BLOCK_WIDGETS: [
      'MultiSlider',
      'BeatVisual',
      'Sliders',
      'Sequencer',
      'Position',
      'Dials',
      'Image',
      'Text',
    ],
  },
  {
    LOOPS: 2,
    ONE_SHOTS: 4,
    MAX_CLIPS_PER_LOOP: 4,
    RESET_MS_MIN: 3000,
    RESET_MS_MAX: 8000,
    BLOCK_WIDGETS: ['Position', 'BigDial', 'Text'],
  },
  {
    ...initialSongSetting,
    BLOCK_WIDGETS: [],
  },
  {
    LOOPS: 1,
    ONE_SHOTS: 1,
    MAX_CLIPS_PER_LOOP: 10,
    RESET_MS_MIN: 5000,
    RESET_MS_MAX: 7000,
    BLOCK_WIDGETS: [
      'MultiSlider',
      'BeatVisual',
      'BigDial',
      'Sliders',
      'Sequencer',
      'MeterThing',
      'Position',
      'Dials',
      'Button',
    ],
  },
  {
    LOOPS: 2,
    ONE_SHOTS: 3,
    MAX_CLIPS_PER_LOOP: 4,
    RESET_MS_MIN: 500,
    RESET_MS_MAX: 3000,
    BLOCK_WIDGETS: everythingBut(['SwitchSong']),
  },
  {
    LOOPS: 1,
    ONE_SHOTS: 5,
    MAX_CLIPS_PER_LOOP: 1,
    RESET_MS_MIN: 4000,
    RESET_MS_MAX: 8000,
    BLOCK_WIDGETS: everythingBut([
      'SwitchSong',
      'Text',
      'Sequencer',
      'BeatVisual',
    ]),
  },
  {
    LOOPS: 4,
    ONE_SHOTS: 3,
    MAX_CLIPS_PER_LOOP: 2,
    RESET_MS_MIN: 1000,
    RESET_MS_MAX: 12000,
    BLOCK_WIDGETS: everythingBut(['SwitchSong', 'MultiSlider']),
  },
];

// side-effects in SETTINGS!!
export const loadNextSongSettings = () => {
  const settings = songSettings[G.CUR_SONG_SETTING_IDX];
  G.CUR_SONG_SETTING_IDX = (G.CUR_SONG_SETTING_IDX + 1) % songSettings.length;

  if (G.CUR_SONG_SETTING_IDX === 0) {
    songSettings = shuffleArray(songSettings);
  }
  SETTINGS.SONG = settings;
};
