import React, { useMemo } from 'react';
import { Dial as NexusDial } from 'react-nexusui';
import C from '../../globals/constants';
import SETTINGS from '../../globals/settings';
import { useColorizeOnReady } from '../../hooks/useColorizeOnReady';
import { useScalarWidgetParam } from '../../hooks/useScalarWidgetParam';
import { WidgetComponent } from '../../types/widget';

export const BigDial: WidgetComponent = (props) => {
  const { onChange, min, max, value } = useScalarWidgetParam();
  const { width, height, fillColor, accentColor } = props;
  const size = useMemo(() => Math.min(width, height) - C.BOX_MARGIN, [
    width,
    height,
  ]);

  const onReady = useColorizeOnReady(fillColor, accentColor);
  return (
    <NexusDial
      size={[size, size]}
      interaction="radial"
      mode="relative"
      max={max}
      min={min}
      value={value}
      onChange={onChange}
      onReady={onReady}
    />
  );
};
BigDial.widgetName = 'BigDial';

BigDial.sizes = () => {
  const isFeatured =
    SETTINGS.SONG.BLOCK_WIDGETS && SETTINGS.SONG.BLOCK_WIDGETS.length > 6;

  return isFeatured
    ? [
        [2, 2],
        [3, 2],
        [2, 3],
        [3, 3],
        [3, 4],
        [4, 3],
      ]
    : [
        [2, 2],
        [3, 2],
        [2, 3],
      ];
};
