import debounce from 'lodash/debounce';
import * as Tone from 'tone';
import assetPreloader from '../../../globals/assetPreloader';
import { isCloseTo } from '../../../utils';
import prng from '../../PRNG';
import ChangeController from '../ChangeController';
import { loopInitialValues } from './loopParams';

const QUIET_DB = -80;
const SWITCHINESS = 0.2;

export default class PoolController extends ChangeController {
  players: Tone.Player[];
  nodes: {
    panVol: Tone.PanVol;
  };

  changeRateMs = 1;
  currentPlayerIdx = 0;
  doChanges = true;
  state: 'stopped' | 'started' = 'stopped';

  constructor(filenames: string[]) {
    super();
    this.nodes = {
      panVol: new Tone.PanVol(prng.float() * 2 - 1, 0),
    };
    this.players = filenames.map((url, i) => {
      return assetPreloader
        .getPlayer(url)
        .set({
          loop: true,
          mute: i !== this.currentPlayerIdx,
        })
        .connect(this.nodes.panVol);
    });

    this.setLoopiness(loopInitialValues.loopiness);
  }

  connectOutput = (node: Tone.ToneAudioNode) => this.nodes.panVol.connect(node);

  getFadeTimeMs = () => this.changeRateMs * 0.05;

  fadeOut = (n?: Tone.Player) => {
    if (!n || n.mute === true || isCloseTo(n.volume.value, QUIET_DB)) return;
    const ms = this.getFadeTimeMs();

    n.volume.rampTo(QUIET_DB, ms / 1000);

    setTimeout(() => {
      n.mute = true;
    }, ms * 1.1);
  };
  fadeIn = (n?: Tone.Player) => {
    if (!n || n.mute === false || isCloseTo(n.volume.value, 0)) return;

    const ms = this.getFadeTimeMs();
    n.set({
      volume: QUIET_DB,
      mute: false,
    });
    n.volume.rampTo(0, ms / 1000);
  };

  change = () => {
    if (!this.doChanges) return;
    const r = prng.float();

    if (r < SWITCHINESS) {
      this.goToNextPlayer();
    } else {
      this.fadeIn(this.getCurrentPlayer());
    }

    this.setTimeout(this.change, this.changeRateMs * (1 + prng.float() * 1.5));
  };

  startChanges = () => {
    this.doChanges = true;
    this.change();
    this.setTimeout(this.change, this.changeRateMs);
  };

  stopChanges = () => {
    this.doChanges = false;
  };

  setSpeed = (val: number) => {
    this.players.forEach((pl) => pl.set({ playbackRate: val }));
  };
  setVolume = (val: number) => {
    this.nodes.panVol.volume.rampTo(val, 0.02);
  };
  setReverse = (val: boolean) => {
    this.players.forEach((pl) => pl.set({ reverse: val }));
  };

  getCurrentPlayer = () => this.players[this.currentPlayerIdx];

  goToNextPlayer = () => {
    this.currentPlayerIdx = (this.currentPlayerIdx + 1) % this.players.length;

    this.players.forEach((pl, i) => {
      if (i === this.currentPlayerIdx) {
        this.fadeIn(pl);
      } else {
        this.fadeOut(pl);
      }
    });
  };

  setLoopiness = debounce(
    (val: number) => {
      const inverse = 1 - val;
      // MORE LOOPINESS = FASTER CHANGING
      this.changeRateMs = (Math.pow(inverse * 1.7, 2) + 0.1) * 1000; // (0.2s ~- 5s) exponential

      this.stopChanges();
      this.startChanges();
    },
    100,
    { maxWait: 300 },
  );

  stop = () => {
    this.stopChanges();
    this.state = 'stopped';
    this.players.forEach((pl) => {
      if (pl.state === 'started') pl.stop();
    });
  };

  start = () => {
    this.state = 'started';
    this.players.forEach((pl) => {
      try {
        if (pl.loaded) {
          pl.start();
        } else {
          pl.autostart = true;
        }
      } catch (e) {
        console.log(e);
      }
    });
    this.startChanges();
  };

  dispose = () => {
    this.clearAllTimeoutsAndIntervals();
    this.players.forEach((pl) => pl.dispose());
  };
}
