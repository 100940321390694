// Creates a pseudo-random value generator. The seed must be an integer.
class PRNG {
  A: number;
  B: number;
  seed: number;

  constructor() {
    this.A = 2147483647;
    this.B = 16807;

    this.seed = this.B % this.A;
    if (this.seed <= 0) this.seed += this.A - 1;
  }

  setNewSeed(seed) {
    if (seed !== undefined)
      this.seed =
        seed >= 0
          ? Math.round(seed) % this.A
          : Math.round(this.A - seed) % this.A;
  }

  // Returns a pseudo-random value between 1 and 2^32 - 2.
  next() {
    return (this.seed = (this.seed * this.B) % this.A);
  }

  // Returns a pseudo-random value between 1 and 2^32 - 2.
  int() {
    return this.next();
  }
  // Returns a boolean
  bool() {
    const val = this.next();
    return val % 2 === 0;
  }

  // Returns a pseudo-random value between min (inclusive) and max
  between(min, max) {
    const mod = max - min;
    return (this.next() % mod) + min;
  }

  // Returns a pseudo-random floating point number in range [0, 1).
  float() {
    // We know that result of next() will be 1 to 2147483646 (inclusive).
    return (this.next() - 1) / (this.A - 1);
  }
}

const prng = new PRNG();
prng.setNewSeed(Math.random() * 10000);

export default prng;
