const G = {
  CUR_TEXT_IDX: 0,
  CUR_IMG_IDX: 0,
  CUR_SONG_SETTING_IDX: 0,
  TEST: false,
};

if (window.localStorage.getItem('TEST') === 'true') {
  G.TEST = true;
}
export default G;
