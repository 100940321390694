import React, { useContext, useEffect, useState } from 'react';
import useIsMounted from '../../hooks/useIsMounted';
import { ContextAppEvents } from '../ContextAppEvents';
import './style.css';
import Img from './question.svg';

const elements = {
  root: document.getElementById('AboutModal') as HTMLDivElement,
  reactRoot: document.getElementById('root') as HTMLDivElement,
  contents: document.getElementById('AboutModalContents') as HTMLDivElement,
  close: document.getElementById('AboutModalClose') as HTMLButtonElement,
};

export const AboutButton = () => {
  const [open, setOpen] = useState(false);

  const { onAppEvent } = useContext(ContextAppEvents);
  const isMounted = useIsMounted();

  const openAboutPage = () => {
    onAppEvent({ name: 'openAbout' });
    setOpen(true);
  };

  useEffect(() => {
    if (open) {
      if (elements.root) {
        elements.root.style.display = 'flex';
        elements.root.classList.add('AboutModalAnimateIn');
        setTimeout(() => {
          if (isMounted.current) {
            if (elements.reactRoot) {
              elements.reactRoot.style.display = 'none';
            }
            if (elements.root) {
              elements.root.style.position = 'relative';
            }
          }
        }, 500);
      }
    } else {
      if (elements.reactRoot) {
        elements.reactRoot.style.display = 'initial';
      }
      if (elements.root) {
        elements.root.style.display = 'none';
        elements.root.style.position = 'fixed';
        elements.root.classList.remove('AboutModalAnimateIn');
      }
    }
  }, [isMounted, open]);

  useEffect(() => {
    if (elements.close) {
      elements.close.onclick = () => {
        if (isMounted.current) {
          onAppEvent({ name: 'closeAbout' });
          setOpen(false);
        }
      };
    }
    return () => {
      if (elements.close) {
        elements.close.onclick = null;
      }
    };
  }, [isMounted, onAppEvent]);

  return (
    <>
      <button className="AboutButton" onClick={openAboutPage}>
        <img src={Img} alt="about" />
      </button>
    </>
  );
};
