import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import prng from '../../classes/PRNG';
import { ContextLightBox } from '../../components/LightBox';
import assetPreloader from '../../globals/assetPreloader';
import { getNextImage } from '../../globals/imageManifest';
import SETTINGS from '../../globals/settings';
import useIsMounted from '../../hooks/useIsMounted';
import { WidgetComponent } from '../../types/widget';
import './styles.css';

export const Image: WidgetComponent = () => {
  const [imgIdx, setImgIdx] = useState(0);
  const thisImage = useMemo(() => getNextImage(), []);
  const sequenceSpeed = useMemo(() => prng.float() * 3000 + 1000, []);

  const isMounted = useIsMounted();
  const { openImage, openImageSequence } = useContext(ContextLightBox);

  // cycle through images
  useEffect(() => {
    if (Array.isArray(thisImage)) {
      setImgIdx((n) => (n + 1) % thisImage.length);
    }
    const iid = setInterval(() => {
      if (isMounted.current && Array.isArray(thisImage)) {
        setImgIdx((n) => (n + 1) % thisImage.length);
      }
    }, sequenceSpeed);
    return () => window.clearInterval(iid);
  }, [thisImage, isMounted, sequenceSpeed]);

  const { url, type, thumbnail } = useMemo(() => {
    const result = (() => {
      if (Array.isArray(thisImage)) {
        return thisImage[imgIdx];
      } else return thisImage;
    })();
    return {
      ...result,
      thumbnail: assetPreloader.getImage(result.thumbnail),
    };
  }, [imgIdx, thisImage]);

  const onOpen = useCallback(() => {
    if (Array.isArray(thisImage)) {
      return openImageSequence(
        thisImage.map((img) => img.url),
        imgIdx,
      );
    } else {
      return openImage(url, type);
    }
  }, [imgIdx, openImage, openImageSequence, thisImage, type, url]);

  return (
    <img
      onClick={onOpen}
      className="imageWidgetOuter"
      alt="project visual"
      src={thumbnail}
    />
  );
};

Image.widgetName = 'Image';
Image.sizes = () => {
  const isFeatured =
    SETTINGS.SONG.BLOCK_WIDGETS && SETTINGS.SONG.BLOCK_WIDGETS.length > 6;
  return isFeatured
    ? [
        [2, 3],
        [3, 4],
        [3, 2],
        [2, 1],
        [3, 1],
        [2, 2],
        [1, 2],
        [1, 1],
        [4, 4],
        [4, 3],
      ]
    : [
        [2, 3],
        [3, 4],
        [3, 2],
        [2, 1],
        [3, 1],
        [2, 2],
      ];
};
