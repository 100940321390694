import React, { useContext } from 'react';
import { AboutButton } from '../AboutButton';
import { ContextAppState } from '../ContextAppState';
import { Loading } from '../Loading';
import { PlayButton } from '../PlayButton';
import { Swapper } from '../Swapper';
import './styles.css';

export function SwapperContainer() {
  const {
    state: { isLoading },
  } = useContext(ContextAppState);
  return (
    <div className="SwapperContainer">
      <div className="AppButtons">
        <PlayButton />
        <AboutButton />
      </div>
      <Swapper />
      {isLoading && <Loading />}
    </div>
  );
}
export function SwapperContainerPlaceHolder(
  props: React.PropsWithChildren<{}>,
) {
  return (
    <div className="SwapperContainer">
      <div className="AppButtons">
        <PlayButton />
        <AboutButton />
      </div>
      {props.children}
    </div>
  );
}
