const palettes = [
  { backgroundColor: '#492A6080', fillColor: '#FFF4', accentColor: '#9FFA7F' },
  {
    backgroundColor: '#49111C80',
    fillColor: '#FDD4',
    accentColor: '#A9CEF4',
  },
  { backgroundColor: '#36494E80', fillColor: '#FFF4', accentColor: '#F9B29D' },
  { backgroundColor: '#EB642440', fillColor: '#FFF4', accentColor: '#88CFCC' },
  { backgroundColor: '#492A6080', fillColor: '#FFF4', accentColor: '#88CFCC' },
  {
    backgroundColor: '#49111C80',
    fillColor: '#FDD4',
    accentColor: '#9FFA7F',
  },
  { backgroundColor: '#36494E80', fillColor: '#FFF4', accentColor: '#A9CEF4' },
  { backgroundColor: '#EBAA2440', fillColor: '#FFF4', accentColor: '#F9B29D' },
];

export const useWidgetColors = () => {
  // const {
  //   state: { songManifest, isLoading },
  // } = useContext(ContextAppState);
  // const [palette, setPalette] = useState(palettes[0]);
  // const songName = songManifest?.name || '';
  // useEffect(() => {
  //   if (!songManifest || isLoading) return;
  //   const idx = songManifests.findIndex((sm) => sm.name === songName);
  //   setPalette(palettes[idx % palettes.length] || palettes[0]);
  // }, [isLoading, songManifest, songName]);

  return palettes[0];
};
