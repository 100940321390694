import React from 'react';
import ProviderContextAppEvents from '../ContextAppEvents';
import ProviderContextAppState from '../ContextAppState';
import ProviderContextLoadingMusic from '../ContextLoadingMusic';
import ProviderContextParams from '../ContextParams';
import ProviderContextSwapManager from '../ContextSwapManager';

export function Providers(props: React.PropsWithChildren<{}>) {
  return (
    <ProviderContextAppState>
      <ProviderContextSwapManager>
        <ProviderContextParams>
          <ProviderContextLoadingMusic>
            <ProviderContextAppEvents>
              {props.children}
            </ProviderContextAppEvents>
          </ProviderContextLoadingMusic>
        </ProviderContextParams>
      </ProviderContextSwapManager>
    </ProviderContextAppState>
  );
}
