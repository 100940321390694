import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ContextAppEvents } from '../../components/ContextAppEvents';
import { ContextAppState } from '../../components/ContextAppState';
import { getNextSongOptionAndImage } from '../../globals/songManifests';
import { WidgetComponent } from '../../types/widget';
import './style.css';

export const SwitchSong: WidgetComponent = () => {
  const { onAppEvent } = useContext(ContextAppEvents);
  const {
    state: { songManifest, isLoading },
  } = useContext(ContextAppState);
  const curSongName = songManifest?.name || '';
  const [targetSongName, setTargetSongName] = useState('');
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    if (isLoading) return;
    const newSong = getNextSongOptionAndImage(curSongName);

    setTargetSongName(newSong.song.name);
    setImageUrl(newSong.imageUrl);
  }, [curSongName, isLoading]);

  const onClick = useCallback(() => {
    onAppEvent({ name: 'changeSong', songName: targetSongName });
  }, [onAppEvent, targetSongName]);
  return (
    <img
      src={imageUrl}
      className="SwitchSong"
      onClick={onClick}
      alt="geometric animal face"
    />
  );
};
SwitchSong.widgetName = 'SwitchSong';
SwitchSong.sizes = [
  [2, 2],
  [3, 3],
  [4, 4],
];
