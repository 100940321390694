import debounce from 'lodash/debounce';
import * as Tone from 'tone';
import assetPreloader from '../../../globals/assetPreloader';
import { getOneShotOutput, isCloseTo } from '../../../utils';
import { volParamToDecibels } from '../../../utils/params';
import prng from '../../PRNG';
import { ParamPubSub } from '../../ParamPubSub';
import ChangeController from '../ChangeController';
import { subscribeController } from '../subscribeController';
import {
  getOneShotParams,
  oneShotInitialSequenceRetrigger,
  oneShotInitialVolume,
} from './oneShotParams';
import {
  getSequencerRetriggerRate,
  oneShotSubscriptions,
} from './subscriptions';

export const DELAY_FEEDBACK_ON = 0.3;

export default class OneShotController extends ChangeController {
  player: Tone.Player;
  nodes: {
    panVol: Tone.PanVol;
    delay: Tone.FeedbackDelay;
  };
  name: string;
  pubSub: ParamPubSub;
  sequencerRetriggerRate = getSequencerRetriggerRate(
    oneShotInitialSequenceRetrigger,
  );
  updateOutput: (items: string[]) => void;
  unSubscribeEvents?: () => void;

  constructor(
    filename: string,
    oneShotName: string,
    pubSub: ParamPubSub,
    updateOutput: (items: string[]) => void,
  ) {
    super();
    this.updateOutput = updateOutput;
    this.nodes = {
      delay: new Tone.FeedbackDelay(
        prng.float() * 0.5 + 0.05,
        prng.float() > 0.5 ? 0 : DELAY_FEEDBACK_ON,
      ).set({ wet: 1 }),
      panVol: new Tone.PanVol(prng.float() * 2 - 1, 0),
    };
    this.player = assetPreloader.getPlayer(filename);
    this.player.loop = false;
    this.player.set({ volume: volParamToDecibels(oneShotInitialVolume) });

    // player => delay => panVol => output
    this.player.connect(this.nodes.delay);
    this.nodes.delay.connect(this.nodes.panVol);

    this.name = oneShotName;
    this.pubSub = pubSub;
  }

  connectOutput = (node: Tone.ToneAudioNode) => this.nodes.panVol.connect(node);

  getParams = () =>
    getOneShotParams(
      this.name,
      this.nodes.panVol.pan.value,
      this.nodes.delay.toSeconds(this.nodes.delay.delayTime.value),
      isCloseTo(this.nodes.delay.feedback.value, DELAY_FEEDBACK_ON),
    );

  trigger = () => {
    try {
      if (this.player.loaded) {
        const isDonePlaying = this.player.state === 'stopped';
        const doTrigger =
          isDonePlaying || prng.float() < this.sequencerRetriggerRate;
        if (doTrigger) this.player.start(0);
      }
    } catch (e) {
      console.error(e);
    }
  };

  debouncedTrigger = debounce(() => {
    this.trigger();
  }, 150);
  stop = () => {
    this.player.stop();
  };

  dispose = () => {
    this.unSubscribeEvents && this.unSubscribeEvents();
    this.nodes.panVol.dispose();
    this.player.dispose();
  };
  //// subscriptions ======================= //

  subscribeEvents = () => {
    this.unSubscribeEvents = subscribeController(
      this,
      this.pubSub,
      oneShotSubscriptions,
      () => this.updateOutput(getOneShotOutput(this)),
    );
  };
}
