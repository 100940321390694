export default class ChangeController {
  timeoutIds: number[] = [];
  intervalIds: number[] = [];

  setTimeout = (func: () => void, time: number) => {
    const id = window.setTimeout(
      () => window.requestAnimationFrame(func),
      time,
    );
    this.timeoutIds.push(id);
  };

  setInterval = (func: () => void, time: number) => {
    const id = window.setInterval(
      () => window.requestAnimationFrame(func),
      time,
    );
    this.intervalIds.push(id);
  };

  clearAllTimeoutsAndIntervals = () => {
    this.timeoutIds.forEach((id) => window.clearTimeout(id));
    this.intervalIds.forEach((id) => window.clearInterval(id));
    this.timeoutIds = [];
    this.intervalIds = [];
  };
}
