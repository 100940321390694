import React, { useMemo } from 'react';
import { Dial as NexusDial } from 'react-nexusui';
import C from '../../globals/constants';
import { useColorizeOnReady } from '../../hooks/useColorizeOnReady';
import { useScalarWidgetParam } from '../../hooks/useScalarWidgetParam';
import { WidgetComponent, WidgetProps } from '../../types/widget';

const Dial = (props: WidgetProps) => {
  const { onChange, min, max, value } = useScalarWidgetParam();
  const onReady = useColorizeOnReady(props.fillColor, props.accentColor);

  return (
    <NexusDial
      size={[C.BOX_WIDTH * 0.9, C.BOX_WIDTH * 0.75]}
      interaction="radial"
      mode="relative"
      max={max}
      min={min}
      value={value}
      onChange={onChange}
      onReady={onReady}
    />
  );
};
export const Dials: WidgetComponent = (props) => {
  const numDials = props.rows * props.columns;
  const dummyArray = useMemo(() => Array(numDials).fill(0), [numDials]);
  return (
    <>
      {dummyArray.map((_, i) => (
        <Dial key={i} {...props} />
      ))}
    </>
  );
};

Dials.widgetName = 'Dials';
Dials.sizes = [
  [1, 1],
  [1, 2],
  [2, 1],
  [3, 1],
  [2, 2],
];
