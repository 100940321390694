import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { BoxTree } from '../../classes/BoxTree';
import { SwapManager } from '../../classes/SwapManager';
import C from '../../globals/constants';
import { BoxData } from '../../types/box';
import { AppEventHandler } from '../../types/events';
import { ContextAppState } from '../ContextAppState';

interface ContextSwapManagerValue {
  swapper?: SwapManager;
  boxes: BoxData[];
  updateNumber: number;
  onAppEvent: AppEventHandler;
}

export const ContextSwapManager = createContext<ContextSwapManagerValue>({
  updateNumber: 0,
  boxes: [],
  onAppEvent: () => {
    return;
  },
});

const ProviderContextSwapManager: React.FunctionComponent<{}> = ({
  children,
}) => {
  const [swapper, setSwapper] = useState<SwapManager | undefined>();
  const [boxTree, setBoxTree] = useState<BoxTree | undefined>();
  const [boxes, setBoxes] = useState<BoxData[]>([]);
  const [updateNumber, setUpdateNumber] = useState(0);

  const {
    state: { songManifest, isLoading },
  } = useContext(ContextAppState);
  const songName = songManifest?.name || '';

  useEffect(() => {
    const swp = new SwapManager(() => setUpdateNumber((u) => u + 1));

    const boxTreeRoot = new BoxTree(
      {
        columns: C.NUM_COLS,
        rows: C.NUM_ROWS,
        offsetColumns: 0,
        offsetRows: 0,
      },
      swp,
    );

    setSwapper(swp);
    setBoxTree(boxTreeRoot);

    setBoxes(boxTreeRoot.getLeafData());
    swp.startRandomResets();

    return () => {
      boxTreeRoot.unregister();
      swp.stopRandomResets();
      swp.dispose();
    };
  }, [songName]);

  useEffect(() => {
    setBoxes(boxTree?.getLeafData() || []);
  }, [boxTree, updateNumber]);

  const onAppEvent: AppEventHandler = useCallback(
    (event) => {
      switch (event.name) {
        case 'openVideo':
        case 'openImage':
        case 'tabBlur':
          if (swapper) {
            swapper.stopRandomResets();
          }
          return;
        case 'closeVideo':
        case 'closeImage':
        case 'tabFocus':
          if (swapper) {
            swapper.startRandomResets();
          }
          return;
      }
    },
    [swapper],
  );

  const contextValue: ContextSwapManagerValue = useMemo(() => {
    return {
      swapper,
      boxes: isLoading ? [] : boxes,
      updateNumber,
      onAppEvent,
    };
  }, [boxes, isLoading, onAppEvent, swapper, updateNumber]);

  return (
    <ContextSwapManager.Provider value={contextValue}>
      {children}
    </ContextSwapManager.Provider>
  );
};

export default ProviderContextSwapManager;
