import SETTINGS from '../globals/settings';
import { shuffleArray } from '../utils';
import prng from './PRNG';
import ChangeController from './changeScopes/ChangeController';

interface SwapActions {
  swap: () => boolean;
}

const getRandomResetTime = () =>
  Math.round(
    SETTINGS.SONG.RESET_MS_MIN +
      prng.float() * (SETTINGS.SONG.RESET_MS_MAX - SETTINGS.SONG.RESET_MS_MIN),
  );

export class SwapManager extends ChangeController {
  actions: { [key: string]: SwapActions | undefined };
  ids: string[];
  doRandomSwap = false;
  onResetRandom: () => void;

  removeSpaceHandler: () => void;

  constructor(onResetRandom: () => void) {
    super();

    this.actions = {};
    this.ids = [];
    this.onResetRandom = onResetRandom;

    const handler = (ev: KeyboardEvent) => {
      if (ev.code === 'Space') {
        this.resetRandom();
      }
    };
    document.addEventListener('keyup', handler);

    this.removeSpaceHandler = () => {
      document.removeEventListener('keyup', handler);
    };
  }

  dispose = () => {
    if (this.removeSpaceHandler) this.removeSpaceHandler();
    this.clearAllTimeoutsAndIntervals();
  };

  swapItem = (id: string): boolean => {
    const itemActions = this.actions[id];
    if (itemActions) {
      return itemActions.swap();
    }
    return false;
  };

  startRandomResets = () => {
    this.doRandomSwap = true;
    const resetRandomRepeat = () => {
      if (this.doRandomSwap) {
        this.resetRandom();
        const tilNext = Math.round(getRandomResetTime());
        this.setTimeout(resetRandomRepeat, tilNext);
      }
    };
    this.setTimeout(resetRandomRepeat, Math.round(getRandomResetTime()));
  };

  stopRandomResets = () => {
    this.doRandomSwap = false;
  };

  resetRandom = () => {
    if (this.ids.length === 0) return;
    const shuffledIds = shuffleArray(this.ids);
    for (let i = 0; i <= shuffledIds.length; i++) {
      const didSwap = this.swapItem(shuffledIds[i]);
      if (didSwap) return this.onResetRandom();
    }
  };

  register = (itemId: string, actions: SwapActions) => {
    this.actions[itemId] = actions;
    this.ids.push(itemId);
  };

  unregister = (itemId: string) => {
    if (this.actions[itemId]) {
      delete this.actions[itemId];
    }
    this.ids = this.ids.filter((id) => id !== itemId);
  };
}
