import { useEffect, useState } from 'react';
import { SequenceParam, SequenceValue } from '../classes/changeScopes/types';
import { useParamContext } from './useParamContext';

export const useSequenceWidgetParam = () => {
  const [value, setValue] = useState<boolean[][] | null>(null);
  const [numRows, setNumRows] = useState(1);
  const [numCols, setNumCols] = useState(1);
  const [onChange, setOnChange] = useState<((v: number) => void) | undefined>();

  const { state, pubSub } = useParamContext();

  useEffect(() => {
    if (!state || !pubSub) return;

    const param = state.getRandomParamOfType('sequence', pubSub) as
      | SequenceParam
      | undefined;
    if (!param) return;
    // subscribe to changes
    const subscriptionId = pubSub.subscribe(param.name, (change) =>
      setValue((prev) => {
        const newValue = prev?.map((row) => row.map((col) => col)) || null;
        if (newValue === null) return null;
        const val = change as SequenceValue;
        newValue[val.row][val.column] = val.state;
        return newValue;
      }),
    );
    // set initial values
    setNumRows(param.rows);
    setNumCols(param.columns);
    setValue(param.val);
    // publish changes from Widget to other subscribers
    const onChange = (v: SequenceValue) =>
      pubSub.publish({
        paramName: param.name,
        value: v,
      });

    setOnChange(() => onChange);

    return () => pubSub.unsubscribe(subscriptionId);
  }, [pubSub, state]);

  return {
    value,
    numRows,
    numCols,
    onChange,
  };
};
