import debounce from 'lodash/debounce';
import * as MakePreloader from 'preloader';
import * as Tone from 'tone';
import { SongManifest } from '../types/fileManifest';
import { animalImageUrls } from './animalImageUrls';
import { imageManifest } from './imageManifest';
import SETTINGS from './settings';

interface Preloader {
  on: (
    event: 'progress' | 'complete',
    handler: (progress: number) => void,
  ) => void;
  add: (url: string, options?: { onComplete?: () => void }) => void;
  addAudio: (url, options?: { onComplete?: () => void }) => void;
  addImage: (url, options?: { onComplete?: () => void }) => void;
  load: () => void;
  get: (url: string) => { currentSrc: string };
  reset: () => void;
  stopLoad: () => void;
}

class AssetPreloader {
  preloader: Preloader;
  songsLoaded: string[] = [];
  urlsLoaded: string[] = [];
  maxLoadTimeout = 0;

  onUpdate?: (progress: number) => void;
  constructor() {
    this.preloader = MakePreloader({
      xhrImages: false,
    }) as Preloader;

    this.preloader.on('progress', this.onProgress);
    this.preloader.on('complete', () => this.onProgress(1));
  }

  onProgress = debounce(
    (progress: number) => {
      if (this.onUpdate) {
        this.onUpdate(progress);
        if (progress >= 1) {
          this.onUpdate = undefined;
        }
      }
    },
    200,
    {
      leading: true,
      maxWait: 200,
    },
  );

  private load = (file: string) => {
    if (!this.urlsLoaded.includes(file)) {
      this.preloader.add(file, {
        onComplete: () => {
          this.urlsLoaded.push(file);
        },
      });
    }
  };

  loadAnimalImages = () => {
    animalImageUrls.forEach((img) => {
      if (!this.urlsLoaded.includes(img)) {
        this.preloader.addImage(img, {
          onComplete: () => {
            this.urlsLoaded.push(img);
          },
        });
      }
    });
  };

  loadImageThumbnails = () => {
    imageManifest.visuals.forEach((assetOrAssets) => {
      if (Array.isArray(assetOrAssets)) {
        assetOrAssets.forEach(({ thumbnail }) => this.load(thumbnail));
      } else {
        this.load(assetOrAssets.thumbnail);
      }
    });
  };

  loadSongManifest = (
    sm: SongManifest,
    onUpdate?: (progress: number) => void,
  ) => {
    window.clearTimeout(this.maxLoadTimeout);
    if (this.songsLoaded.includes(sm.name)) {
      if (onUpdate) onUpdate(1);
      return;
    }
    this.preloader.reset();
    this.loadAnimalImages();
    this.loadImageThumbnails();
    if (onUpdate)
      this.onUpdate = (p: number) => {
        if (p >= 1) {
          this.songsLoaded.push(sm.name);
          sm.audio.loops.forEach((name) => this.urlsLoaded.push(name));
          sm.audio.oneshots.forEach((name) => this.urlsLoaded.push(name));
        }
        onUpdate(p);
      };
    sm.audio.loops.forEach((file) => this.preloader.addAudio(file));
    sm.audio.oneshots.forEach((file) => this.preloader.addAudio(file));
    this.preloader.load();

    this.maxLoadTimeout = window.setTimeout(() => {
      this.onUpdate = undefined;
      // fake a "completed" and just start;
      if (onUpdate) {
        onUpdate(1);
      }
    }, SETTINGS.MAX_LOAD_MS);
  };

  getPlayer = (url: string) => {
    if (this.urlsLoaded.includes(url)) {
      const asset = this.preloader.get(url);
      return new Tone.Player(asset.currentSrc);
    } else {
      console.log('loaded Player from URL');
      return new Tone.Player(url);
    }
  };

  getImage = (url: string) => {
    if (this.urlsLoaded.includes(url)) {
      const asset = this.preloader.get(url);
      if (!asset.currentSrc) {
        return url;
      }
      return asset.currentSrc;
    } else {
      return url;
    }
  };
}

const assetPreloader = new AssetPreloader();
export default assetPreloader;
