import Nexus from 'nexusui';
import { NO_OP } from '../../utils/index';

export class MonkeyPatchedSequencer extends Nexus.Sequencer {
  addTouchListeners() {
    this.preClick = this.preMove = this.preRelease = NO_OP;
    this.click = this.move = this.release = NO_OP;
    this.preTouch = this.preTouchMove = this.preTouchRelease = NO_OP;
    this.touch = this.touchMove = this.touchRelease = NO_OP;

    this.currentElement = false;

    this.element.addEventListener('touchstart', (e) => {
      const element = document.elementFromPoint(
        e.targetTouches[0].clientX,
        e.targetTouches[0].clientY,
      );
      if (!element) return;
      const cell = this.cells[element.index];
      if (!cell) return;
      this.paintbrush = !cell.state;
      cell.down(this.paintbrush);
      this.currentElement = element.index;
      e.preventDefault();
      e.stopPropagation();
    });

    this.element.addEventListener('touchmove', (e) => {
      const element = document.elementFromPoint(
        e.targetTouches[0].clientX,
        e.targetTouches[0].clientY,
      );
      if (!element) return;
      const cell = this.cells[element.index];
      if (!cell) return;
      if (element.index !== this.currentElement) {
        if (this.currentElement >= 0) {
          const pastCell = this.cells[this.currentElement];
          if (pastCell) pastCell.up();
        }
        cell.down(this.paintbrush);
      } else {
        cell.bend();
      }
      this.currentElement = element.index;
      e.preventDefault();
      e.stopPropagation();
    });

    this.element.addEventListener('touchend', (e) => {
      // no touches to calculate because none remaining
      const cell = this.cells[this.currentElement];
      if (!cell) return;

      cell.up();
      this.interacting = false;
      this.currentElement = false;
      e.preventDefault();
      e.stopPropagation();
    });
  }
}
