import { shuffleArray } from '../utils';

export const animalImageUrls = shuffleArray([
  './animals/bear.svg',
  './animals/elephant.svg',
  './animals/fox.svg',
  './animals/giraffe.svg',
  './animals/hippo.svg',
  './animals/owl.svg',
  './animals/thing.svg',
]);
