import React, { useMemo } from 'react';
import MyButton from '../../components/MyButton';
import C from '../../globals/constants';
import { useBooleanWidgetParam } from '../../hooks/useBooleanWidgetParam';
import { useColorizeOnReady } from '../../hooks/useColorizeOnReady';
import { WidgetComponent, WidgetProps } from '../../types/widget';

const size = [C.BOX_WIDTH * 0.9, C.BOX_WIDTH * 0.7];

const AButton = ({ fillColor, accentColor }: WidgetProps) => {
  const { onChange, value } = useBooleanWidgetParam();
  const onReady = useColorizeOnReady(fillColor, accentColor);
  return (
    <MyButton
      size={size}
      mode="toggle"
      onChange={onChange}
      state={value}
      onReady={onReady}
    />
  );
};

export const Button: WidgetComponent = (props) => {
  const numDials = props.rows * props.columns;
  const dummyArray = useMemo(() => Array(numDials).fill(0), [numDials]);
  return (
    <>
      {dummyArray.map((_, i) => (
        <AButton key={i} {...props} />
      ))}
    </>
  );
};
Button.widgetName = 'Button';

Button.sizes = [
  [1, 2],
  [2, 1],
  [1, 1],
];
