import { initialSongSetting } from './songSettings';

const SETTINGS = {
  WONKINESS: 0,
  MIN_LOAD_MS: 6000,
  MAX_LOAD_MS: 20000,
  SONG_SWITCH_MIN_S: 75,
  SONG_SWITCH_MAX_S: 140,
  SONG: initialSongSetting,
};

export default SETTINGS;
