import * as Tone from 'tone';
import SETTINGS from '../../../globals/settings';
import { SongManifest } from '../../../types/fileManifest';
import { getSongOutput, isCloseTo, shuffleArray } from '../../../utils';
import {
  getRandomBooleanMatrix,
  getRandomNumberList,
} from '../../../utils/params';
import { ParamPubSub } from '../../ParamPubSub';
import ChangeController from '../ChangeController';
import LoopController from '../loop/Loop';
import OneShotController from '../oneshot/OneShot';
import { subscribeController } from '../subscribeController';
import { Param } from '../types';
import { getSongParams, songInitialParamValues } from './songParams';
import { songSubscriptions } from './subscriptions';

type AudioNode = Tone.ToneAudioNode;

export const VOLUME_RAMP_SEC = 0.7;
const DECIBELS_QUIET = -100;
const DECIBELS_MUFFLED = -17.5;

export const SONG_FILTERS = (() => {
  const _LP_FREQ = 14000;
  const _HP_FREQ = 120;
  return {
    LP_FREQ: _LP_FREQ,
    HP_FREQ: _HP_FREQ,
    LP_Q: 1,
    HP_Q: 1,
    MUFFLED_LP_FREQ: _LP_FREQ * 0.3,
    RADIO_EFFECT_LP_FREQ: _HP_FREQ * 4,
    RADIO_EFFECT_HP_FREQ: _HP_FREQ * 5,
    RADIO_EFFECT_LP_Q: 7,
    RADIO_EFFECT_HP_Q: 4,
  };
})();

export default class SongController extends ChangeController {
  name: string;
  pubSub: ParamPubSub;

  loops: LoopController[];
  oneShots: OneShotController[];

  oneShotSequence: boolean[][];
  oneShotSequenceReverse = false;
  oneShotSequenceBeatLength = songInitialParamValues.oneShotSequenceBeatLength;
  currentOneShotBeat = 0;
  currentLoopBeat = 0;
  loopSequenceBeatLength = songInitialParamValues.loopSequenceBeatLength;
  loopSequenceReverse = false;
  playSequences = false;

  unSubscribeEvents?: () => void;

  updateOutput: (items: string[]) => void;

  modifiers = {
    currentIndex: 0,
    values: getRandomNumberList(0.1, 1.7, 29).map((n) => (n <= 1 ? n : n * n)),
  };

  nodes: {
    volume: Tone.Volume;
    muter: Tone.Volume;
    compressor: Tone.Compressor;
    // reverb: Tone.Reverb;
    LP: Tone.Filter;
    HP: Tone.Filter;
    limiter: Tone.Limiter;
  };

  constructor(
    mediaContext: SongManifest,
    pubSub: ParamPubSub,
    updateOutput: (items: string[]) => void,
  ) {
    super();
    this.updateOutput = updateOutput;
    this.name = mediaContext.name;

    // set up signal chain
    this.nodes = {
      volume: new Tone.Volume(DECIBELS_QUIET),
      muter: new Tone.Volume(0),
      compressor: new Tone.Compressor(-34, 12),
      // reverb: new Tone.Reverb(0.6).set({ wet: 0.3 }),
      LP: new Tone.Filter(SONG_FILTERS.LP_FREQ, 'lowpass').set({
        Q: SONG_FILTERS.LP_Q,
      }),
      HP: new Tone.Filter(SONG_FILTERS.HP_FREQ, 'highpass').set({
        Q: SONG_FILTERS.HP_Q,
      }),
      limiter: new Tone.Limiter(0),
    };
    this.nodes.volume.chain(
      this.nodes.muter,
      this.nodes.compressor,
      // this.nodes.reverb,
      this.nodes.LP,
      this.nodes.HP,
      this.nodes.limiter,
    );
    this.nodes.limiter.toDestination();

    this.oneShotSequence = getRandomBooleanMatrix(
      SETTINGS.SONG.ONE_SHOTS,
      5,
      0.3,
    );
    this.pubSub = pubSub;

    const clipsPerLoop = Math.min(
      SETTINGS.SONG.MAX_CLIPS_PER_LOOP,
      mediaContext.audio.loops.length / SETTINGS.SONG.LOOPS,
    );
    // assign clips to loops ("loop pools")
    this.loops = new Array(SETTINGS.SONG.LOOPS).fill(0).map((_, i) => {
      const startIdx = Math.floor(i * clipsPerLoop);
      const endIdx = Math.floor((i + 1) * clipsPerLoop);
      return new LoopController(
        mediaContext.audio.loops.slice(startIdx, endIdx),
        `${this.name}_loop${i}`,
        pubSub,
        updateOutput,
      );
    });

    this.oneShots = mediaContext.audio.oneshots
      .slice(0, SETTINGS.SONG.ONE_SHOTS)
      .map(
        (filename, i) =>
          new OneShotController(
            filename,
            `${this.name}_oneShot${i}: [${filename}]`,
            pubSub,
            updateOutput,
          ),
      );

    // hook up audio nodes to Song Master volume node
    this.loops.forEach((loop) => loop.connectOutput(this.nodes.volume));
    this.oneShots.forEach((oneShot) =>
      oneShot.connectOutput(this.nodes.volume),
    );
  }

  getModifier = () => {
    const idx = this.modifiers.currentIndex;
    this.modifiers.currentIndex =
      (this.modifiers.currentIndex + 1) % this.modifiers.values.length;
    const val = this.modifiers.values[idx];
    if (!val) console.log('NO VALUE FOR MODIFIER');
    return val || 1;
  };
  getParams = () => {
    // get params for song and all loops+oneshots
    const songParams = getSongParams(this.name, this.oneShotSequence);
    return songParams
      .concat(
        this.loops.reduce<Param[]>(
          (prev, cur) => prev.concat(cur.getParams()),
          [],
        ),
      )
      .concat(
        this.oneShots.reduce<Param[]>(
          (prev, cur) => prev.concat(cur.getParams()),
          [],
        ),
      );
  };

  start = (time = VOLUME_RAMP_SEC, muffled = false) => {
    this.loops.forEach((loop) => loop.pool.start());
    this.playSequences = true;
    this.updateLoopsBeat();
    this.updateOneShotsBeat();
    if (muffled) {
      this.muffle();
    } else {
      this.nodes.volume.volume.rampTo(0, time);
    }
  };

  stop = (time = VOLUME_RAMP_SEC, onComplete?: () => void) => {
    this.playSequences = false;
    this.nodes.volume.volume.rampTo(DECIBELS_QUIET, time);
    this.setTimeout(() => {
      if (isCloseTo(this.nodes.volume.volume.value, DECIBELS_QUIET)) {
        this.loops.forEach((loop) => loop.pool.stop());
      }
      if (onComplete) onComplete();
    }, time * 1000 + 100);
  };

  muffle = (time = VOLUME_RAMP_SEC) => {
    this.nodes.volume.volume.rampTo(DECIBELS_MUFFLED, time);
    this.nodes.LP.frequency.rampTo(SONG_FILTERS.MUFFLED_LP_FREQ, time);
  };
  unmuffle = (time = VOLUME_RAMP_SEC) => {
    this.nodes.volume.volume.rampTo(0, time);
    this.nodes.LP.frequency.rampTo(SONG_FILTERS.LP_FREQ, time);
  };

  updateOneShotsBeat = () => {
    if (!this.playSequences) return;
    this.pubSub.publish({
      paramName: `${this.name}_currentOneShotBeat`,
      value: this.currentOneShotBeat,
    });
    const triggersThisBeat = this.oneShotSequence.map(
      (row) => row[this.currentOneShotBeat] || false,
    );
    this.oneShots.forEach((oneShot, i) => {
      if (triggersThisBeat[i]) {
        oneShot.trigger();
      } else {
        oneShot.stop();
      }
    });
    // update and continue
    const updateAmt = this.oneShotSequenceReverse ? -1 : 1;
    this.currentOneShotBeat = (this.currentOneShotBeat + 5 + updateAmt) % 5;

    this.setTimeout(
      this.updateOneShotsBeat,
      Math.round(this.oneShotSequenceBeatLength * 1000 * this.getModifier()),
    );
  };

  updateLoopsBeat = () => {
    if (!this.playSequences) return;
    this.pubSub.publish({
      paramName: `${this.name}_currentLoopBeat`,
      value: this.currentLoopBeat,
    });
    // update loop values based on their own lists
    this.loops.forEach((loop) => loop.updateToBeat(this.currentLoopBeat));

    // update and continue
    const updateAmt = this.loopSequenceReverse ? -1 : 1;
    this.currentLoopBeat = (this.currentLoopBeat + 5 + updateAmt) % 5;

    this.setTimeout(
      this.updateLoopsBeat,
      Math.round(this.loopSequenceBeatLength * 1000 * this.getModifier()),
    );
  };

  getRandomAudioNodes = (qty: number): AudioNode[] => {
    const nodes: AudioNode[] = this.loops
      .map((loop): AudioNode => loop.pool.nodes.panVol)
      .concat(this.oneShots.map((oneShot): AudioNode => oneShot.nodes.panVol));
    const shuffled = shuffleArray(nodes);
    return shuffled.slice(0, qty);
  };

  dispose = () => {
    this.playSequences = false;
    this.clearAllTimeoutsAndIntervals();
    //dispose of audio nodes;
    this.unSubscribeEvents && this.unSubscribeEvents();
    this.loops.forEach((loop) => loop.dispose());
    this.oneShots.forEach((oneShot) => oneShot.dispose());

    this.nodes.volume.dispose();
    this.nodes.muter.dispose();
    this.nodes.HP.dispose();
    this.nodes.LP.dispose();
    this.nodes.compressor.dispose();
    this.nodes.limiter.dispose();
    // this.nodes.reverb.dispose();
  };

  //// subscriptions ======================= //

  subscribeEvents = () => {
    const unSubscribeSong = subscribeController(
      this,
      this.pubSub,
      songSubscriptions,
      () => this.updateOutput(getSongOutput(this)),
    );
    this.loops.forEach((loop) => loop.subscribeEvents());
    this.oneShots.forEach((oneShot) => oneShot.subscribeEvents());

    this.unSubscribeEvents = () => {
      unSubscribeSong();
      this.loops.forEach(
        (loop) => loop.unSubscribeEvents && loop.unSubscribeEvents(),
      );
      this.oneShots.forEach(
        (oneShot) => oneShot.unSubscribeEvents && oneShot.unSubscribeEvents(),
      );
    };
  };
}
