import { useEffect, useState } from 'react';
import { BooleanParam } from '../classes/changeScopes/types';
import { useParamContext } from './useParamContext';

export const useBooleanWidgetParam = () => {
  const [value, setValue] = useState(false);
  const [onChange, setOnChange] = useState<
    ((v: boolean) => void) | undefined
  >();

  const { state, pubSub } = useParamContext();

  useEffect(() => {
    if (!state || !pubSub) return;

    const param = state.getRandomParamOfType('boolean', pubSub) as
      | BooleanParam
      | undefined;
    if (!param) return;
    // subscribe to changes
    const subscriptionId = pubSub.subscribe(param.name, (v) =>
      setValue(v as boolean),
    );

    setValue(param.val);
    // publish changes from Widget
    const onChange = (v: boolean) =>
      pubSub.publish({
        paramName: param.name,
        value: v,
      });

    setOnChange(() => onChange);

    return () => pubSub.unsubscribe(subscriptionId);
  }, [pubSub, state]);

  return {
    value,
    onChange,
  };
};
