import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import assetPreloader from '../../globals/assetPreloader';
import SETTINGS from '../../globals/settings';
import { songManifests } from '../../globals/songManifests';
import { loadNextSongSettings } from '../../globals/songSettings';
import useIsMounted from '../../hooks/useIsMounted';
import { AppEventHandler } from '../../types/events';
import { SongManifest } from '../../types/fileManifest';

export interface AppState {
  state: 'playing' | 'image' | 'video' | 'about';
  muted: boolean;
  focused: boolean;
  songManifest?: SongManifest;
  isLoading: boolean;
}

const appInitialState: AppState = {
  state: 'playing',
  muted: false,
  focused: true,
  isLoading: true,
};

interface ContextAppStateValue {
  state: AppState;
  onAppEvent: AppEventHandler;
}

export const ContextAppState = createContext<ContextAppStateValue>({
  state: appInitialState,
  onAppEvent: () => {
    return;
  },
});

const ProviderContextAppState: React.FunctionComponent<{}> = ({ children }) => {
  const [state, setState] = useState<AppState>(appInitialState);
  const isMounted = useIsMounted();

  const changeSong = useCallback(
    (songName?: string) => {
      // load next set of song-level settings
      loadNextSongSettings();
      // default to first song if no songName is passed (or not found)
      const songManifest =
        songManifests.find((m) => m.name === songName) || songManifests[0];
      setState((s) => ({
        ...s,
        songManifest,
        isLoading: true,
      }));

      // load song assets
      const startTime = Date.now();

      setTimeout(() => {
        if (isMounted.current) {
          assetPreloader.loadSongManifest(songManifest, (progress) => {
            if (isMounted.current) {
              if (progress >= 1) {
                const elapsed = Date.now() - startTime;
                const delayTimeToFlagLoaded =
                  Math.max(SETTINGS.MIN_LOAD_MS - 1000 - elapsed, 0) + 1000;

                setTimeout(() => {
                  if (isMounted.current) {
                    setState((s) => ({
                      ...s,
                      isLoading: false,
                    }));
                  }
                }, delayTimeToFlagLoaded);
              }
            }
          });
        }
      }, 1000);
    },
    [isMounted],
  );

  useEffect(() => {
    changeSong();
  }, [changeSong]);

  const onAppEvent: AppEventHandler = useCallback(
    (event) => {
      switch (event.name) {
        case 'changeSong':
          return changeSong(event.songName);
        case 'mute':
          return setState((s) => ({ ...s, muted: true }));
        case 'unmute':
          return setState((s) => ({ ...s, muted: false }));

        case 'openVideo':
          return setState((s) => ({ ...s, state: 'video' }));
        case 'closeVideo':
          return setState((s) => ({ ...s, state: 'playing' }));

        case 'tabBlur':
          return setState((s) => ({ ...s, focused: false }));
        case 'tabFocus':
          return setState((s) => ({ ...s, focused: true }));

        case 'openImage':
          return setState((s) => ({ ...s, state: 'image' }));
        case 'closeImage':
          return setState((s) => ({ ...s, state: 'playing' }));
      }
    },
    [changeSong],
  );

  // useEffect(() => {
  //   const id = setInterval(() => {
  //     console.log(state);
  //   }, 1000);

  //   return () => window.clearInterval(id);
  // });
  const contextValue: ContextAppStateValue = useMemo(() => {
    return {
      state,
      onAppEvent,
    };
  }, [onAppEvent, state]);
  return (
    <ContextAppState.Provider value={contextValue}>
      {children}
    </ContextAppState.Provider>
  );
};

export default ProviderContextAppState;
