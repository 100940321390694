import Nexus from 'nexusui';
import React from 'react';
import { NO_OP, getId } from '../../utils/index';

export const MyMultiSlider = React.memo(function Multislider({
  size,
  max,
  min,
  smoothing,
  step,
  values,
  candycane,
  numberOfSliders,
  onChange = NO_OP,
  onReady = NO_OP,
}) {
  const multislider = React.useRef(null);
  const elementId = React.useRef(`nexus-ui-multislider-${getId()}`);
  React.useEffect(() => {
    multislider.current = new Nexus.Multislider(elementId.current, {
      size,
      max,
      min,
      smoothing,
      numberOfSliders,
      step,
      values,
      candycane,
    });
    onReady(multislider.current);
    multislider.current.on('change', (newState) => {
      onChange(newState);
    });
    return () => {
      multislider.current.destroy();
    };
  }, [
    candycane,
    max,
    min,
    numberOfSliders,
    onChange,
    onReady,
    size,
    smoothing,
    step,
    values,
  ]);
  React.useEffect(() => {
    if (multislider.current === null) return;
    if (!Array.isArray(size)) {
      return;
    }
    multislider.current.resize(...size);
  }, [size]);
  React.useEffect(() => {
    if (multislider.current === null) return;
    if (min === undefined) return;
    multislider.current.min = min;
  }, [min]);
  React.useEffect(() => {
    if (multislider.current === null) return;
    if (max === undefined) return;
    multislider.current.max = max;
  }, [max]);
  React.useEffect(() => {
    if (multislider.current === null) return;
    if (smoothing === undefined) return;
    multislider.current.smoothing = smoothing;
  }, [smoothing]);
  React.useEffect(() => {
    if (multislider.current === null) return;
    if (step === undefined) return;
    multislider.current.step = step;
  }, [step]);

  React.useEffect(() => {
    if (multislider.current === null) return;
    if (values === undefined || !Array.isArray(values)) return;
    multislider.current.setAllSliders(values);
  }, [values]);

  return React.createElement('div', { id: elementId.current });
});
