import React, { useContext, useEffect } from 'react';
import prng from '../../classes/PRNG';
import SETTINGS from '../../globals/settings';
import { getNextSongOptionAndImage } from '../../globals/songManifests';
import useIsMounted from '../../hooks/useIsMounted';
import { ContextAppEvents } from '../ContextAppEvents';
import { ContextAppState } from '../ContextAppState';

const SongSwitchTimer: React.FunctionComponent<{}> = () => {
  const {
    state: { songManifest, isLoading },
  } = useContext(ContextAppState);
  const curSongName = songManifest?.name || '';

  const { onAppEvent } = useContext(ContextAppEvents);
  const isMounted = useIsMounted();

  useEffect(() => {
    if (!songManifest || isLoading) return;
    const newSong = getNextSongOptionAndImage(curSongName);
    const songPlayTime =
      prng.between(SETTINGS.SONG_SWITCH_MIN_S, SETTINGS.SONG_SWITCH_MAX_S) *
      1000;

    let tid: undefined | number;
    const doSongSwitchTimeout = () => {
      tid = window.setTimeout(() => {
        if (isMounted.current) {
          if (document.hasFocus()) {
            onAppEvent({
              name: 'changeSong',
              songName: newSong.song.name,
            });
          } else {
            doSongSwitchTimeout();
          }
        }
      }, songPlayTime);
    };

    doSongSwitchTimeout();
    return () => {
      if (tid) window.clearTimeout(tid);
    };
  }, [curSongName, isLoading, isMounted, onAppEvent, songManifest]);

  return null;
};

export default SongSwitchTimer;
