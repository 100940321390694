import React, { useState } from 'react';
import { ClickToBegin } from '../ClickToBegin';
import { LightBox } from '../LightBox';
import { Providers } from '../Providers';
import SongSwitchTimer from '../SongSwitchTimer';
import {
  SwapperContainer,
  SwapperContainerPlaceHolder,
} from '../SwapperContainer';
import { TestOutput } from '../TestOutput';
import './styles.css';

export function App() {
  const [started, setStarted] = useState(false);
  return (
    <div className="App">
      {!started && (
        <SwapperContainerPlaceHolder>
          <ClickToBegin onClick={() => setStarted(true)} />
        </SwapperContainerPlaceHolder>
      )}
      {started && (
        <TestOutput>
          <Providers>
            <LightBox>
              <SongSwitchTimer />
              <SwapperContainer />
            </LightBox>
          </Providers>
        </TestOutput>
      )}
    </div>
  );
}
