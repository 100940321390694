import { ParamPubSub } from '../ParamPubSub';
import { ParamValue } from './types';

type ControllerType = { name: string };

export type Subscriptions<T extends ControllerType> = {
  [paramName: string]: (controller: T, value: ParamValue) => void;
};

export const subscribeController = <T extends ControllerType>(
  controller: T,
  pubSub: ParamPubSub,
  subscriptions: Subscriptions<T>,
  onUpdate?: () => void,
) => {
  const paramNames = Object.getOwnPropertyNames(subscriptions);
  const subscriptionIds: string[] = [];

  paramNames.forEach((paramName) => {
    const subscriberFunction = subscriptions[paramName];
    if (!subscriberFunction) return;
    // add controller name to scope message name
    const fullName = controller.name + '_' + paramName;
    const id = pubSub.subscribe(fullName, (val) => {
      subscriberFunction(controller, val);
      if (onUpdate) onUpdate();
    });
    subscriptionIds.push(id);
  });

  // return unsubcriber function
  return () => subscriptionIds.forEach((id) => pubSub.unsubscribe(id));
};
