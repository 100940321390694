export default [
  [
    'Ver lo que sientes, ya llegaste ahí.',
    '¿Qué te rodea en estos momentos?',
    '¿Cómo se transmite el conocimiento?',
    'El miliciano multidominio, ex automan, detrás de un podio',
    'Su aliento huele a amoniaco, el ionio corre por sus venas',
    'con aversión a las boletas electorales por correo.',
  ],
  [
    'El animal unimodal que reside dentro de él no es un mamífero',
    'sino más bien un daimon, ex alumno de una larga tradición de locos.',
    'La audición de este miliciano se concentra en las ultimas y escucha mucho',
    'la adulación de los tontos a su alrededor.',
  ],
  [
    'En su tiempo libre, toca la mandola mientras desfigura a las doncellas',
    'dilatando la dilución de la misma tierra en la que vivimos.',
    '¿Para qué luchamos en estos tiempos?',
    'Planos de dibujos animados con aroma a flora',
    'goteo de refrigerante.',
  ],
  [
    'Los Patroons modernos pueden controlar',
    'los portales por los que pasan los Narcos con cola,',
    'sus pelotones aplauden como mapaches colocados en alto',
    'prueba de su obsesión por el porno polar situado en una montaña.',
    'Los halcones caen sobre localizadores designados por dos puntos,',
    'pronto su pantorrilla se utiliza',
    'como fuente para los logotipos de sus amantes.',
  ],
  [
    'Es raro, ¿por qué me siento así? ',
    'Fraude en el mundo tiene el olor de',
    'un cuenco de sangre de boa',
    'mezclada con una pésima harina.',
    'El defecto es terrible sin embargo,',
    'somos distantes.',
    '¿Cómo pasamos por este bardo?',
    '¿Cuándo volverá a florecer el Budwood?',
  ],
  [
    'El lobo y el búho asestó el golpe',
    'a la desaparición del miliciano,',
    'ofreciendo una pelea borrosa que terminó',
    'con él en una inundación de acento.',
  ],
  'Ver lo que sientes, ya llegaste ahí.',
  '¿Qué te rodea en estos momentos?',
  '¿Cómo se transmite el conocimiento?',
  'El miliciano multidominio, ex automan, detrás de un podio',
  'Su aliento huele a amoniaco, el ionio corre por sus venas',
  'El animal unimodal que reside dentro de él no es un mamífero',
  'sino más bien un daimon, ex alumno de una larga tradición de locos.',
  'la adulación de los tontos a su alrededor.',
  'En su tiempo libre, toca la mandola mientras desfigura a las doncellas',
  'dilatando la dilución de la misma tierra en la que vivimos.',
  '¿Para qué luchamos en estos tiempos?',
  'Planos de dibujos animados con aroma a flora',
  'goteo de refrigerante.',
  'los portales por los que pasan los Narcos con cola,',
  'prueba de su obsesión por el porno polar situado en una montaña.',
  'Los halcones caen sobre localizadores designados por dos puntos,',
  'pronto su pantorrilla se utiliza como fuente para los logotipos de sus amantes.',
  'Fraude en el mundo tiene el olor de',
  'mezclada con una pésima harina.',
  'El defecto es terrible sin embargo, somos distantes.',
  '¿Cómo pasamos por este bardo?',
  '¿Cuándo volverá a florecer el Budwood?',
  'El lobo y el búho asestó el golpe a la desaparición del miliciano,',
  'ofreciendo una pelea borrosa que terminó con él en una inundación de acento.',
];
