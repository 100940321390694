import React, { useMemo } from 'react';
import { BeatCursor } from '../../components/BeatCursor';
import { MyMultiSlider } from '../../components/MyMultiSlider';
import C from '../../globals/constants';
import SETTINGS from '../../globals/settings';
import { useColorizeOnReady } from '../../hooks/useColorizeOnReady';
import { useScalarListWidgetParam } from '../../hooks/useScalarListWidgetParam';
import { WidgetComponent } from '../../types/widget';
import { PlaceHolder } from '../PlaceHolder';

export const MultiSlider: WidgetComponent = (props) => {
  const { values, min, max, onChange } = useScalarListWidgetParam();
  const { width, height } = props;
  const size = useMemo(() => [width - C.BOX_MARGIN, height - C.BOX_MARGIN], [
    height,
    width,
  ]);
  const onReady = useColorizeOnReady(props.fillColor, props.accentColor);
  if (!onChange) return <PlaceHolder {...props} />;
  return (
    <>
      <MyMultiSlider
        size={size}
        min={min}
        max={max}
        values={values}
        numberOfSliders={5}
        candycane={3}
        onChange={onChange}
        onReady={onReady}
      />
      <BeatCursor width={width} height={height} beatName="currentLoopBeat" />
    </>
  );
};

MultiSlider.widgetName = 'MultiSlider';
MultiSlider.sizes = () => {
  return SETTINGS.SONG.LOOPS > 2
    ? [
        [3, 2],
        [2, 3],
        [2, 2],
        [3, 3],
        [1, 2],
        [2, 1],
        [1, 3],
        [2, 4],
      ]
    : [
        [3, 2],
        [2, 3],
        [2, 2],
        [2, 1],
      ];
};
