import { useEffect, useState } from 'react';
import { ScalarParam } from '../classes/changeScopes/types';
import { useParamContext } from './useParamContext';

export const useScalarWidgetParam = () => {
  const [value, setValue] = useState(0);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(1);
  const [onChange, setOnChange] = useState<((v: number) => void) | undefined>();

  const { state, pubSub } = useParamContext();

  useEffect(() => {
    if (!state || !pubSub) return;

    const param = state.getRandomParamOfType('scalar', pubSub) as
      | ScalarParam
      | undefined;
    if (!param) return;
    // subscribe to changes
    const subscriptionId = pubSub.subscribe(param.name, (v) =>
      setValue(v as number),
    );
    // set initial values
    setMin(param.min);
    setMax(param.max);
    setValue(param.val);
    // publish changes from Widget to other subscribers
    const onChange = (v: boolean) =>
      pubSub.publish({
        paramName: param.name,
        value: v,
      });

    setOnChange(() => onChange);

    return () => pubSub.unsubscribe(subscriptionId);
  }, [pubSub, state]);

  return {
    value,
    max,
    min,
    onChange,
  };
};
