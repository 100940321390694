import React from 'react';
import C from '../../globals/constants';
import { useSequenceBeat } from '../../hooks/useSequenceBeat';
import './style.css';

interface Props {
  width: number;
  height: number;
  beatName: string;
}
export const BeatCursor = (props: Props) => {
  const { width, height } = props;
  const { beatNumber } = useSequenceBeat(props.beatName);

  const cursorStart = C.BOX_MARGIN / 2 - 2;
  const colWidth = (width - C.BOX_MARGIN) / 5;
  return (
    <div
      className="beatCursor"
      style={{
        left: cursorStart + beatNumber * colWidth,
        top: cursorStart,
        height: height - cursorStart * 2,
        width: colWidth + 4,
      }}
    />
  );
};
