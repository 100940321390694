import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ParamPubSub } from '../../classes/ParamPubSub';
import { ParamState } from '../../classes/ParamState';
import SongController, {
  VOLUME_RAMP_SEC,
} from '../../classes/changeScopes/song/Song';
import { AppEventHandler } from '../../types/events';
import { ContextAppState } from '../ContextAppState';
import { ContextTestOutput } from '../TestOutput';

interface ContextParamsValue {
  state?: ParamState;
  pubSub?: ParamPubSub;
  songCtrl?: SongController;
  onAppEvent: AppEventHandler;
}

export const ContextParams = createContext<ContextParamsValue>({
  onAppEvent: () => {
    return;
  },
});

const ProviderContextParams: React.FunctionComponent<{}> = ({ children }) => {
  const [paramState, setParamState] = useState<ParamState | undefined>();
  const [paramPubSub, setParamPubSub] = useState<ParamPubSub | undefined>();
  const [songCtrl, setSongCtrl] = useState<SongController | undefined>();

  const {
    state: { state, songManifest, isLoading, muted },
  } = useContext(ContextAppState);

  const { updateOutput } = useContext(ContextTestOutput);
  useEffect(() => {
    if (!songManifest || isLoading) return;
    const state = new ParamState();
    const pubSub = new ParamPubSub(state);

    // set up new song and tracks
    const sc = new SongController(songManifest, pubSub, updateOutput);
    setSongCtrl(sc);
    const songAndTrackParams = sc.getParams();
    state.add(songAndTrackParams);
    sc.subscribeEvents();
    setParamState(state);
    setParamPubSub(pubSub);
    sc.start();

    return () => {
      sc.unSubscribeEvents && sc.unSubscribeEvents();
      sc.stop(1, () => sc.dispose());
    };
  }, [isLoading, songManifest, updateOutput]);

  useEffect(() => {
    if (!songCtrl) return;
    songCtrl.nodes.muter.set({ mute: muted });
  }, [muted, songCtrl]);

  const onAppEvent: AppEventHandler = useCallback(
    (event) => {
      switch (event.name) {
        case 'mute':
          if (songCtrl) {
            songCtrl.nodes.muter.volume.rampTo(-60, 0.05);
            setTimeout(() => {
              songCtrl.nodes.muter.set({ mute: true });
            }, 100);
          }
          return;
        case 'unmute':
          if (songCtrl) {
            songCtrl.nodes.muter.set({ mute: false });
            songCtrl.nodes.muter.volume.rampTo(0, 0.05);
          }
          return;
        case 'openVideo':
        case 'tabBlur':
        case 'openAbout':
          if (songCtrl) {
            songCtrl.stop();
          }
          return;
        case 'closeVideo':
        case 'tabFocus':
        case 'closeAbout':
          if (songCtrl) {
            if (state === 'playing') songCtrl.start();
            else if (state === 'image') songCtrl.start(VOLUME_RAMP_SEC, true);
          }
          return;
        case 'openImage':
          if (songCtrl) {
            songCtrl.muffle();
          }
          return;
        case 'closeImage':
          if (songCtrl) {
            songCtrl.unmuffle();
          }
          return;
      }
    },
    [songCtrl, state],
  );

  const contextValue: ContextParamsValue = useMemo(() => {
    return {
      state: paramState,
      pubSub: paramPubSub,
      songCtrl,
      onAppEvent,
    };
  }, [onAppEvent, paramPubSub, paramState, songCtrl]);
  return (
    <ContextParams.Provider value={contextValue}>
      {children}
    </ContextParams.Provider>
  );
};

export default ProviderContextParams;
