export default [
  [
    'I like chaotic',
    '            我一 睇到就鍾意           ',
    "Once I see it, I'll like it.",
  ],
  [
    'Because material speaks for itself.',
    '            這件作品一睇就知內裏意思            ',
    'Just a little glimpse the viewer will immediately understand the meaning inside.',
  ],
];
