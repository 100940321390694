import Nexus from 'nexusui';
import React from 'react';
import { NO_OP, getId } from '../../utils/index';
import { MonkeyPatchedSequencer } from './MonkeyPatchedSequencer';

const MySequencer = React.memo(function Sequencer({
  size = [400, 200],
  mode = 'toggle',
  rows = 5,
  columns = 10,
  onChange = NO_OP,
  onStep = NO_OP,
  onReady = NO_OP,
  value = [],
}) {
  const sequencer = React.useRef(null);
  const elementId = React.useRef(`nexus-ui-sequencer-${getId()}`);
  React.useEffect(() => {
    window.Nexus = Nexus;
    sequencer.current = new MonkeyPatchedSequencer(elementId.current, {
      size,
      mode,
      columns,
      rows,
    });
    onReady(sequencer.current);
    sequencer.current.on('change', (newState) => {
      onChange(newState);
    });
    sequencer.current.on('step', (newState) => {
      onStep(newState);
    });

    return () => {
      sequencer.current.destroy();
    };
  }, [columns, mode, onChange, onReady, onStep, rows, size]);
  React.useEffect(() => {
    if (sequencer.current === null || !Array.isArray(size)) return;
    sequencer.current.resize(...size);
  }, [size]);
  React.useEffect(() => {
    if (sequencer.current === null) return;
    sequencer.current.columns = columns;
  }, [columns]);
  React.useEffect(() => {
    if (sequencer.current === null) return;
    sequencer.current.rows = rows;
  }, [rows]);
  React.useEffect(() => {
    if (sequencer.current === null) return;
    if (value !== undefined && Array.isArray(value) && value.length > 0) {
      sequencer.current.matrix.set.all(value);
    }
  }, [value]);
  return React.createElement('div', { id: elementId.current });
});

export default MySequencer;
