import { useEffect, useState } from 'react';
import { useParamContext } from './useParamContext';

export const useSequenceBeat = (beatName: string) => {
  const [beatNumber, setBeatNumber] = useState(-1);
  const { state, pubSub } = useParamContext();
  const { songCtrl } = useParamContext();
  const songName = songCtrl ? songCtrl.name : '';

  useEffect(() => {
    if (!state || !pubSub || !songName) return;
    const paramName = `${songName}_${beatName}`;

    const param = state.get(paramName);
    if (param) {
      setBeatNumber(param.val as number);
    }
    const subscriptionId = pubSub.subscribe(paramName, (newValue) =>
      setBeatNumber(newValue as number),
    );

    return () => pubSub.unsubscribe(subscriptionId);
  }, [beatName, pubSub, songName, state]);

  return {
    beatNumber,
  };
};
