import FsLightbox from 'fslightbox-react';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { ContextAppEvents } from '../ContextAppEvents';

interface ContextLightBoxValue {
  openImage: (url: string, type: 'image' | 'video' | 'youtube') => void;
  openImageSequence: (urls: string[], openIndex: number) => void;
}

export const ContextLightBox = createContext<ContextLightBoxValue>({
  openImage: () => {
    return;
  },
  openImageSequence: () => {
    return;
  },
});

export function LightBox(props: React.PropsWithChildren<{}>) {
  const [open, setOpen] = useState(false);
  const [imageUrls, setImageUrls] = useState<string[]>([]);
  const [openImageIndex, setOpenImageIndex] = useState(0);
  const [imageType, setImageType] = useState<'image' | 'video' | 'youtube'>(
    'image',
  );

  const { onAppEvent } = useContext(ContextAppEvents);

  const contextValue = useMemo(
    () => ({
      openImage: (url, type) => {
        setImageUrls([url]);
        setImageType(type);
        setOpenImageIndex(0);
        setOpen(true);
      },
      openImageSequence: (urls, openIndex) => {
        setImageUrls(urls);
        setImageType('image');
        setOpenImageIndex(openIndex);
        setOpen(true);
      },
    }),
    [],
  );

  return (
    <>
      {open && (
        <FsLightbox
          sources={imageUrls}
          openOnMount
          exitFullScreenOnClose={true}
          toggler={open}
          type={imageType}
          sourceIndex={openImageIndex}
          onOpen={() =>
            onAppEvent({
              name:
                imageType === 'video' || imageType === 'youtube'
                  ? 'openVideo'
                  : 'openImage',
            })
          }
          onClose={() => {
            onAppEvent({
              name:
                imageType === 'video' || imageType === 'youtube'
                  ? 'closeVideo'
                  : 'closeImage',
            });
            setOpen(false);
          }}
        />
      )}
      <ContextLightBox.Provider value={contextValue}>
        {props.children}
      </ContextLightBox.Provider>
    </>
  );
}
