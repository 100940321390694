import { clamp } from '../utils';
import prng from './PRNG';
import { ParamPubSub } from './ParamPubSub';
import {
  MultiSliderValue,
  Param,
  ParamMessage,
  SequenceValue,
} from './changeScopes/types';

export class ParamState {
  private params: Param[];
  constructor() {
    this.params = [];
  }

  add = (params: Param[]) => {
    params.forEach((param) => {
      this.params.push(param);
    });
  };

  remove = (params: Param[]) => {
    const paramNamesToRemove = params.map((p) => p.name);
    this.params = this.params.filter(
      (param) => !paramNamesToRemove.includes(param.name),
    );
  };

  get = (paramName: string) => {
    return this.params.find((p) => p.name === paramName);
  };

  getRandomParamOfType = (paramType: Param['type'], pubSub: ParamPubSub) => {
    const paramsOfType = this.params.filter(
      (param) => param.type === paramType,
    );
    if (paramsOfType.length === 0) {
      return;
    }
    let tries = 0;
    while (tries < 10) {
      const param =
        paramsOfType[Math.floor(prng.float() * paramsOfType.length)];
      const canSubscribe =
        param.maxRandomSubscribers !== undefined
          ? pubSub.countSubscriptions(param.name) < param.maxRandomSubscribers
          : true;
      if (canSubscribe) {
        return param;
      }
      tries++;
    }
    return;
  };

  onParamMessage = (message: ParamMessage) => {
    const param = this.get(message.paramName);
    if (param) {
      switch (param.type) {
        case 'scalar': {
          param.val = clamp(message.value as number, param.min, param.max);
          return;
        }
        case 'scalarList': {
          const value = message.value as MultiSliderValue;
          param.val = value;
          return;
        }
        case 'boolean': {
          param.val = message.value as boolean;
          return;
        }
        case 'sequence': {
          const value = message.value as SequenceValue;
          // SequenceValue row/columns are zero-indexed
          if (value.row < param.rows && value.column < param.columns) {
            param.val[value.row][value.column] = value.state;
          }
          return;
        }
        case 'choice': {
          if (param.options.includes(message.value as string)) {
            param.val = message.value as string;
          }
          return;
        }
      }
    }
  };
}
