import React, { useContext } from 'react';
import { ContextAppEvents } from '../ContextAppEvents';
import { ContextAppState } from '../ContextAppState';
import UnmuteImg from './speaker-high.svg';
import MuteImg from './speaker-x.svg';
import './style.css';

export const PlayButton = () => {
  const {
    state: { muted, songManifest },
  } = useContext(ContextAppState);
  const { onAppEvent } = useContext(ContextAppEvents);
  if (!songManifest) return null;
  return (
    <button
      className="PlayButton"
      onClick={() => {
        if (muted) onAppEvent({ name: 'unmute' });
        else onAppEvent({ name: 'mute' });
      }}
    >
      {muted ? (
        <img src={MuteImg} alt="unmute audio" />
      ) : (
        <img src={UnmuteImg} alt="mute audio" />
      )}
    </button>
  );
};
