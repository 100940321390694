import { useCallback } from 'react';

export const useColorizeOnReady = (fillColor: string, accentColor: string) => {
  return useCallback(
    (instance: { colorize: (type: string, color: string) => void }) => {
      instance.colorize('accent', accentColor);
      instance.colorize('fill', fillColor);
    },
    [accentColor, fillColor],
  );
};
