import prng from '../classes/PRNG';
import LoopController from '../classes/changeScopes/loop/Loop';
import OneShotController from '../classes/changeScopes/oneshot/OneShot';
import SongController from '../classes/changeScopes/song/Song';
import { volParamToDecibels } from './params';

export const shuffleArray = <T>(arr: T[]): T[] => {
  const copy = arr.slice();
  for (let i = copy.length - 1; i > 0; i--) {
    const j = Math.floor(prng.float() * i);
    const temp = copy[i];
    copy[i] = copy[j];
    copy[j] = temp;
  }
  return copy;
};

let _id = 0;
export const getId = () => {
  _id++;
  return `_internalId_${_id}`;
};

export const NO_OP = function () {
  return undefined;
};

export const clamp = (val: number, min: number, max: number) => {
  return Math.min(Math.max(val, min), max);
};

export const getSongOutput = (song: SongController) => [
  song.name,
  '----',
  `one-shot sequence reverse: ${song.oneShotSequenceReverse}`,
  `one-shot sequence rate: ${song.oneShotSequenceBeatLength.toFixed(2)} sec`,
  `loop sequence reverse: ${song.loopSequenceReverse}`,
  `loop sequence rate: ${song.loopSequenceBeatLength.toFixed(2)} sec`,
  `one-shot sequences: `,
  ...song.oneShotSequence.map((row, i) => {
    const os = song.oneShots[i];
    if (!os) return '';
    return ` - ${os.name}: ${row.map((v) => (v ? 'X' : '- ')).join('')}`;
  }),
];
export const getLoopOutput = (loop: LoopController) => [
  loop.name,
  '----',
  `pan: ${loop.pool.nodes.panVol.pan.value.toFixed(2)}`,
  `reverse: ${loop.reverse}`,
  `loopiness: ${loop.loopiness.toFixed(2)}`,
  `base volume: ${volParamToDecibels(loop.baseVolume).toFixed(2)}`,
  `volume sequence: ${loop.volumeBoostList
    .map((v) => volParamToDecibels(v).toFixed(2))
    .join(', ')}`,
  `base speed: ${Math.pow(2, loop.baseSpeed).toFixed(2)}`,
  `speed sequence: ${loop.speedBoostList.map((v) => v.toFixed(2)).join(', ')}`,
  `vibrato depth: ${loop.nodes.vibrato.depth.value.toFixed(2)}`,
];
export const getOneShotOutput = (o: OneShotController) => [
  o.name,
  `----`,
  `pan: ${o.nodes.panVol.pan.value.toFixed(2)}`,
  `sequence retrigger probability: ${o.sequencerRetriggerRate.toFixed(2)}`,
  `playback rate: ${o.player.playbackRate.toFixed(2)}`,
  `volume: ${o.player.volume.value.toFixed(2)}`,
  `delay On: ${o.nodes.delay.feedback.value > 0.1}`,
  `delay Time: ${o.nodes.delay.toSeconds(o.nodes.delay.delayTime.value)}`,
];

export const isCloseTo = (a: number, b: number) => {
  return Math.abs(a - b) < 0.001;
};
