export default [
  'Que fais-tu ici?',
  "tout ce que vous donnez aujourd'hui, ou quelque chose est déjà arrivé",
  "Ce n'est pas bon.",
  "Mais maintenant, j'ai une place.",
  'Pour notre prix ultime',
  'relation.',
  "1241231221 Heures, deux heures, à l'est et à l'ouest?",
  'Je suis parti un jour.',
  "qu'être humain, je peux certainement dire un par un.",
  'Merci à tous.',
  'Merci merci',
  'Nous pouvons le supprimer avec certains cadres',
  "C'est ce serpent qui a une queue.",
  'Des macro tumeurs aux points de microscope.',
  'Des trucs de radio',
  "Ouais IA me semble de la merde, On dirait que BT n'est pas pour moi non plus.",
  "N'attendez pas que la puissance de la balle soit épuisée de ce groupe.",
  "Si le calendrier est petit, il n'y a pas de noir et blanc",
  [
    'Ecoutez-vous l’une et l’autre',
    'Je ne pense pas que les gens peuvent cacher son nom',
    'Lave ton visage',
    'Alors on en a fini avec la mémoire',
    'Si nous voulons plus que cela, nous souffrons.',
  ],
  'cela peut être fait à tout moment, si nous le pouvons.',
  "Je n'ai pas de régime spécifique ou je dois le faire d'une manière ou d'une autre.",
  "Jaune, oui, je n'ai pas entendu ce qu'il a dit, je suis désolé.",
  "Ce n'est pas correct.",
  "Quelque chose s'est-il passé dans la vraie vie?",
  'développe l’esprit des gens.',
  [
    "J'aime Robin Hood ici.",
    "Je pense que c'est amusant, bon, bon, pas bon maintenant.",
    "Ça s'appelle comment? Luni d'ailleurs?",
    'Si une telle chose peut être doublée.',
    "L’odeur n'est pas Scorpion",
  ],
  'Alors pourquoi regarder',
  'Si nous passons du temps dans la pièce, peut-être',
  'Tu dis vraiment ça?',
  'Les gens ne peuvent pas simplement le supporter.',
  'ici on parle',
  [
    "Quelqu'un envoie.",
    'Nous sommes épuisés.',
    "Le panier dans le fond je peux voler l'avion le permet.",
    'Il y a une petite baguette magique.',
    'incroyable',
    'Un autre morceau de tissu au sol',
    'il y a un avion',
    'Environ 10 minutes auraient pu le perdre. et donc;',
    'Nous écrivons tous la même chose',
    "Évidemment, il n'y a pas un tel endroit",
    'Sonne comme une harpe ou un tambour.',
  ],
  'Donc, je ne sais pas si vous faites du saumon que de nombreux',
  'producteurs ont',
  'Type de comparaison',
  "Qu'est-ce que les gens qui croient encore?",
  '(Oui.)',
  "Maintenant, nous aimons tous les virus, c'est tout.",
  "Je suis presque désolé. Désolé pour ça, et nous ne savions pas ce que c'était pendant un mois.",
];
