import React, { useMemo } from 'react';
import { BeatCursor } from '../../components/BeatCursor';
import MySequencer from '../../components/MySequencer';
import C from '../../globals/constants';
import SETTINGS from '../../globals/settings';
import { useColorizeOnReady } from '../../hooks/useColorizeOnReady';
import { useSequenceWidgetParam } from '../../hooks/useSequenceWidgetParam';
import { WidgetComponent } from '../../types/widget';
import { PlaceHolder } from '../PlaceHolder';
import './style.css';

export const Sequencer: WidgetComponent = (props) => {
  const { width, height } = props;
  const { value, numRows, numCols, onChange } = useSequenceWidgetParam();

  const dimensions = useMemo(
    () => [width - C.BOX_MARGIN, height - C.BOX_MARGIN],
    [width, height],
  );

  const onReady = useColorizeOnReady(props.fillColor, props.accentColor);

  if (!onChange) return <PlaceHolder {...props} />;
  return (
    <>
      <MySequencer
        size={dimensions}
        rows={numRows}
        columns={numCols}
        onChange={onChange}
        value={value}
        onReady={onReady}
      />
      <BeatCursor width={width} height={height} beatName="currentOneShotBeat" />
    </>
  );
};

Sequencer.widgetName = 'Sequencer';
Sequencer.sizes = () => {
  return SETTINGS.SONG.ONE_SHOTS < 3
    ? [
        [1, 2],
        [1, 3],
        [1, 4],
        [1, 5],
        [1, 6],
      ]
    : SETTINGS.SONG.ONE_SHOTS > 5
    ? [
        [3, 2],
        [4, 2],
        [3, 3],
        [4, 3],
        [5, 3],
        [5, 4],
        [4, 5],
      ]
    : [
        [2, 3],
        [2, 4],
        [3, 4],
        [3, 3],
      ];
};
