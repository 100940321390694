import { getId } from '../utils';
import { ParamState } from './ParamState';
import { ParamMessage, ParamValue } from './changeScopes/types';

export type ParamChangeHandler = (value: ParamValue) => void;

interface ParamSubscription {
  subscriptionId: string;
  paramName: string;
  onChange: ParamChangeHandler;
}

export class ParamPubSub {
  state: ParamState;
  subscriptions: {
    [paramName: string]: Array<ParamSubscription> | undefined;
  };
  constructor(state: ParamState) {
    this.state = state;
    this.subscriptions = {};
  }

  subscribe = (paramName: string, onChange: ParamChangeHandler): string => {
    const subscriptionId = getId();
    const newSub = {
      subscriptionId,
      paramName,
      onChange,
    };
    const subs = this.subscriptions[paramName];
    if (subs) {
      subs.push(newSub);
    } else {
      this.subscriptions[paramName] = [newSub];
    }
    return subscriptionId;
  };

  unsubscribe = (subscriptionId: string) => {
    const paramNames = Object.getOwnPropertyNames(this.subscriptions);
    paramNames.forEach((paramName) => {
      const subs = this.subscriptions[paramName];
      if (subs) {
        const newSubs = subs.filter(
          (sub) => sub.subscriptionId !== subscriptionId,
        );
        if (newSubs.length === 0) {
          delete this.subscriptions[paramName];
        } else {
          this.subscriptions[paramName] = newSubs;
        }
      }
    });
  };

  // TODO: make sure this isn't a memory leak
  unsubscibeAll = (paramName: string) => {
    if (this.subscriptions[paramName]) {
      delete this.subscriptions[paramName];
    }
  };

  publish = (message: ParamMessage) => {
    this.state.onParamMessage(message);
    const subs = this.subscriptions[message.paramName];
    subs?.forEach((sub) => {
      sub.onChange(message.value);
    });
  };

  countSubscriptions = (paramName: string) => {
    const subs = this.subscriptions[paramName];
    return subs?.length || 0;
  };
}
