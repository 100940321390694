import { volParamToDecibels } from '../../../utils/params';
import { Subscriptions } from '../subscribeController';
import OneShotController, { DELAY_FEEDBACK_ON } from './OneShot';

export const getSequencerRetriggerRate = (on: boolean) => {
  return on ? 0.75 : 0.1;
};
export const oneShotSubscriptions: Subscriptions<OneShotController> = {
  volume: (os, v) => {
    const value = volParamToDecibels(v as number);
    if (os.player.volume.value === value) return;
    os.player.set({
      volume: value,
    });
    os.debouncedTrigger();
  },
  speedExponent: (os, v) => {
    const value = Math.pow(2, v as number);
    if (os.player.playbackRate === value) return;
    os.player.set({
      playbackRate: value,
    });
    os.debouncedTrigger();
  },
  sequencerRetrigger: (os, v) => {
    const newRate = getSequencerRetriggerRate(Boolean(v));
    if (os.sequencerRetriggerRate === newRate) return;
    os.sequencerRetriggerRate = newRate;
    os.debouncedTrigger();
  },
  pan: (os, v) => {
    if (os.nodes.panVol.pan.value === (v as number)) return;
    os.nodes.panVol.set({
      pan: v as number,
    });
    os.debouncedTrigger();
  },
  delayTime: (os, v) => {
    if (os.nodes.delay.toSeconds(os.nodes.delay.delayTime.value) === v) return;
    os.nodes.delay.set({
      delayTime: v as number,
    });
    os.debouncedTrigger();
  },
  delayOn: (os, v) => {
    const value = Boolean(v) ? DELAY_FEEDBACK_ON : 0;
    if (os.nodes.delay.feedback.value === value) return;
    os.nodes.delay.set({
      feedback: value,
    });
    os.debouncedTrigger();
  },
};
