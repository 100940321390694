import * as Tone from 'tone';
import { Subscriptions } from '../subscribeController';
import LoopController from './Loop';

export const loopSubscriptions: Subscriptions<LoopController> = {
  baseVolume: (loop, v) => {
    loop.baseVolume = v as number;
    loop.updateVolumeValue();
  },
  baseSpeed: (loop, v) => {
    loop.baseSpeed = v as number;
    loop.updateSpeedValue();
  },
  pitchShift: (loop, v) => {
    loop.nodes.pitch.pitch = v as number;
  },
  speedBoostList: (loop, v) => {
    loop.speedBoostList = v as number[];
  },
  volumeBoostList: (loop, v) => {
    loop.volumeBoostList = v as number[];
  },
  reverse: (loop, v) => {
    loop.reverse = Boolean(v);
    loop.pool.setReverse(loop.reverse);
  },
  vibratoDepth: (loop, v) => {
    loop.nodes.vibrato.set({
      depth: v as number,
    });
  },
  loopiness: (loop, v) => {
    if ((v as number) === loop.loopiness) return;
    loop.loopiness = v as number;
    loop.pool.setLoopiness(v as number);
  },
  pan: (loop, v) => {
    loop.pool.nodes.panVol.set({
      pan: v as number,
    });
  },
  miscEffect: (loop, v) => {
    const value = v as number;
    const miscEff = loop.nodes.miscEffect;
    if (!miscEff) return;

    if (miscEff.name === 'Distortion') {
      const ef = miscEff as Tone.Distortion;
      const min = 0;
      const max = 0.9;
      const diff = max - min;
      ef.distortion = min + diff * value;
    } else if (miscEff.name === 'BitCrusher') {
      const ef = miscEff as Tone.BitCrusher;
      const min = 16;
      const max = 2;
      const diff = max - min;
      ef.bits.value = min + diff * value;
    } else if (miscEff.name === 'Phaser') {
      const ef = miscEff as Tone.Phaser;
      const min1 = 0;
      const max1 = 15;
      const diff1 = max1 - min1;
      const min2 = 20;
      const max2 = 510;
      const diff2 = max2 - min2;
      ef.frequency.value = min1 + diff1 * value;
      ef.baseFrequency = min2 + diff2 * value;
    } else if (miscEff.name === 'Reverb') {
      const ef = miscEff as Tone.Reverb;
      const min = 1;
      const max = 0;
      const diff = max - min;
      ef.wet.value = min + diff * value;
    } else if (miscEff.name === 'AutoWah') {
      const ef = miscEff as Tone.AutoWah;
      const min = 1;
      const max = 11;
      const diff = max - min;
      ef.Q.value = min + diff * value;
    }
  },
};
