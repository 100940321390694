import React from 'react';
import C from '../../globals/constants';
import { InnerProps } from '.';
import './style.css';

export const NestedSquares = ({
  width,
  height,
  accentColor,
  values,
}: InnerProps) => {
  const meterWidth = (Math.max(height, width) - C.BOX_MARGIN) * 0.95;
  const baseBorderWidth = meterWidth * 0.125;
  return (
    <div
      className="MeterThingSquareOuter"
      style={{
        height: meterWidth,
        width: meterWidth,
        borderWidth: baseBorderWidth,
        borderColor:
          accentColor +
          `${Math.floor(
            Math.max(0.08, Math.min(1, (values[0] + 55) / 50)) * 255,
          ).toString(16)}`,
      }}
    >
      <div
        className="MeterThingNestedSquare"
        style={{
          borderWidth: baseBorderWidth,
          borderColor:
            accentColor +
            `${Math.floor(
              Math.max(0.08, Math.min(1, (values[1] + 55) / 50)) * 255,
            ).toString(16)}`,
        }}
      >
        <div
          className="MeterThingNestedSquare"
          style={{
            borderWidth: baseBorderWidth,
            borderColor:
              accentColor +
              `${Math.floor(
                Math.max(0.08, Math.min(1, (values[2] + 55) / 50)) * 255,
              ).toString(16)}`,
          }}
        >
          <div
            className="MeterThingNestedSquare"
            style={{
              borderWidth: 0,
              backgroundColor:
                accentColor +
                `${Math.floor(
                  Math.max(0.08, Math.min(1, (values[3] + 55) / 50)) * 255,
                ).toString(16)}`,
            }}
          />
        </div>
      </div>
    </div>
  );
};
