import prng from '../classes/PRNG';

export const getRandomBooleanMatrix = (
  rows: number,
  columns: number,
  fillRate = 0.5,
) => {
  const grid: boolean[][] = [];

  for (let i = 0; i < rows; i++) {
    const thisRow: boolean[] = [];
    for (let j = 0; j < columns; j++) {
      thisRow.push(prng.float() < fillRate);
    }
    grid.push(thisRow);
  }
  return grid;
};

export const getRandomNumberList = (min: number, max: number, quantity = 5) => {
  return new Array(quantity).fill(0).map(() => {
    return prng.float() * (max - min) + min;
  });
};

export const volParamToDecibels = (val: number) => {
  return -1 * Math.pow(val, 2);
};
