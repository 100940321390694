export default [
  [
    'Think human ',
    'Phase shifting away ',
    'Scanning for error',
    'Use correct posture ',
    'The program starts over',
  ],
  [
    'The multidomain militiaman, former automan, stands behind a podium',
    'His breath smells of ammonia, ionium runs through his veins',
    'with an aversion to mailout ballots.',
    'The unimodal animal that resides within him is not a mammal',
    'but rather a daimon, alumni of a long standing tradition of madmen.',
    "This militiaman's audition hones in on ultimas, and hears greatly",
    'the adulation of the fools around him.',
    'At his leisure, he plays mandola while defacing maidans',
    'dilating the diluting of the very earth in which we live.',
  ],
  [
    'Cartoon floorpans scented of flora',
    'drip of coolant.',
    'Modern Patroons can control portals that the Narcos pass through with cola,',
    'their platoons clap like raccoon high on colourant',
    'proof of their obsession with polar porno set on an alp.',
    'Falcons flop onto locators designated by colons,',
    'pronto their calf is used as font for fan logos.',
  ],
  [
    'Fraud in the world',
    'has the odour',
    'of a bowl of boa blood mixed with awful flour.',
    'The flaw is awful,',
    'yet we are aloof.',
    'How do we pass through this bardo?',
    'When will the Budwood flourish again?',
    "Wolf and Owl draw out the blow to the militiaman's demise,",
    'offering a blurred brawl ending him in a drawl flood.',
  ],
  [
    'multidomain',
    'militiaman',
    'adulation',
    'ammonium',
    'unimodal',
    'audition',
    'autodial',
  ],
  [
    'Dilation',
    'dilution',
    'minimum',
    'ammonia',
    'mandola',
    'automan',
    'matilda',
    'mailout',
    'mammal',
    'madman',
    'dimout',
    'diamin',
    'ionium',
    'animal',
    'ultima',
    'maidan',
    'daimon',
    'moduli',
    'alumni',
    'unmold',
    'untold.',
  ],
  [
    'floorpan',
    'carpool',
    'coolant',
    'racoon',
    'colorant',
    'platoon',
    'cartoon',
  ],
  [
    'Patroon',
    'locator',
    'control',
    'coplot',
    'portal',
    'pronto',
    'falcon',
    'proof',
    'colon',
    'flora',
  ],
  [
    'Narco',
    'polar',
    'porno',
    'flop',
    'calf',
    'clap',
    'cola',
    'frap',
    'font',
    'can',
    'alp',
    'fan',
  ],
  [
    'Budwood',
    'odour',
    'awful',
    'brawl',
    'blood',
    'flood',
    'flour',
    'fraud',
    'bardo',
    'drawl',
    'aloof',
    'world',
    'flaw',
  ],
  ['Blur', 'bald', 'blow', 'bowl', 'quad', 'wolf', 'owl', 'boa', 'draw'],
  [
    'I think that kind of thing can be really can really help because it also,',
    "where there's no foreground or background, it's just like,",
    'could double it or that kind of thing. ',
    'as opposed to just sort of washing hellscape',
    'you see there? Yeah.',
    "I assume everyone's okay. ",
    'you can kind of just see it in the room. ',
    "what type of room we'd want to have. ",
    'if we spend a little bit of time in the room, maybe',
    'So I muted it. ',
  ],
  [
    "Oh, wait, what was that? Because now you see these, like, if you're in the room, you can see the",
    'there are some ',
    'muting yourself ',
    "I don't know who it is, or what it is. But I hear myself. ",
  ],
  [
    'Is everyone in the room right now?',
    "I'm actually having trouble, It doesn't let me in.",
    "I think you can I think you're able to join ",
    "I don't know if he'll let you. ",
    "Assuming that that's what they need",
    'Mmm hmm.',
  ],
  [
    "but don't don't wait from you. It might take a while. Thank you.",
    'defacing things and taking pictures with each other ',
    'different buttons and hugs.',
    'What are you referring to? Right? ',
    "And people can't just take it and throw it away.",
  ],
  [
    'You can clone it.',
    'in the chat you type in this the backslash, fly, it allows you to fly.',
  ],
  [
    "There's a little magic wand there.",
    "There's a strange like piece of cloth on the floor and there is an airplane",
    'I might lose about 10 minutes. But yeah. We all sign into the same Kind of room, I guess, are not gonna be like in hubs or in echoes.',
    "I'm not sure when you're creating the lock, though, if you're able to have multiple creators ",
  ],
  [
    'I wonder so is is like as far as the most simple thing?',
    'What are you all doing? ',
    'What should we do?',
    "you'll be able to hear in a radius. But if you like, walk away from it, ",
    'a room within a room.',
    "This, because then it's like people can go into the unknown",
    'So then they that would be like a way to like have a little bit of more like control so to speak ',
    "we tried a playdate where we're just out in the field. ",
  ],
  'a staircase that goes up vertical, ',
  'that would allow people that be farther farther away? ',
  'Yeah. Yeah. Yeah.',
  [
    "Because we're all we're all like, you know, now the virus, it's this ",
    "Mostly. Sorry. Oh, sorry. And we also don't know what it's gonna look like a month. ",
    "we haven't had our second wave yet. ",
  ],
  [
    'I think would end up being',
    "And I think that that'll tambourine, but there are people who want to be ",
    'But these kind of like puzzle pieces ',
    'then you get like a',
    'cookie or something.',
    "It's almost like a Halloween thing.",
  ],
  [
    "I think that's the only one that that's going to be very difficult, if not impossible to move. ",
    "Which doesn't mean anything other than you'll have more power than everyone else.",
    "Unless you make them all hosts, then you're equals.",
    "I'm going to make you the host. And you can stay on there. ",
    'So you should have full control of the like, the motherboard in front of you',
    'back into the mainframe.',
  ],
  [
    'All right, well, thank you other than',
    'others, so',
    "I'm going to stop the recording now. So now you can talk about everything you want.",
  ],
  'VSR N3RGIR 0 MONS I KU u LA.3u 4-Nummimilataim DE MO-TTO GORD',
  '. imik NUT JI <NATO DE MATO US.D',
  '.. PLCA Jf NO CORT GONG DI? folL, PI, BRIR A ',
  '.. DOOR 2SA PAIAVRA PRESA IN G. RGATA CSSE P1LENUE ',
  'HOTEL IN THE WORLD OF QUB ADVANTA TERNIA-1 ',
  'VONRDEMXI MSSUO OALDCO 0 PBITO REST',
  [
    "Let's give a thought I thought she said that she was maybe a bit late on the one o'clock schedule, but we can start if you want to we can.",
    'coming up with some kind of skeleton that we could then take away ',
    "it's sort of this snake eating its tail kind of thing. ",
    'from a macro tumor to microscopic perspective. ',
    'radio object',
  ],
  [
    'Yeah, as if this whole thing is kind of sweet. ',
    'the wider the kaleidoscopic angle ',
    'projecting energy outside this group, and not waiting to the end for that to happen. ',
    'a little calendar, even if not black on white ',
  ],
  [
    "listening to each other's neighborhoods ",
    'as a fire ',
    'in a different bucket, ',
    'launder our identity',
    "everything is touch, it's going through our bodies, at the end. ",
  ],
  [
    'Thank god everyone is good.',
    'So we ended the storage space now',
    "If, if we need more than that we're in trouble.",
    "I don't have a specific recipe or need to do it in a way or the other. ",
    "I'm sorry, I'm losing you a little bit. Extra sound is just hearing it like, Oh, that's good. Thank you. That was it. Whatever. Thank you. Okay. Yeah, I couldn't hear what you're just said, Sorry.",
    "It's not good.",
  ],
  [
    "It's mine. I mean, I don't see why we couldn't try. ",
    "I'm really, I'm really sorry, I couldn't find my data, it will take a little bit of time, but let's just choose a date. ",
    'And and this could be Is there any actual life thing happening?',
    'that is kind of a form of a dialogical shape. And in a polyphonic form, other threads can come out and can continue to feed people imagination in other ways',
    'I am here just as Robin Hood ',
  ],
  [
    'I love seeing kind of the behind the scenes of how people work. ',
    "And I think what's exciting is, well, now, it's kind of creepy, ",
    "Yeah, I think somebody's wallet somebody actually started. ",
    'whatever you need to do in two or three, your quartet intense.',
    "What's his name?",
    'loonies.',
    'loonies?',
  ],
  [
    "Anything, anything, anything you want, whether it's something you come up with today, or it's something that's already in the originals, ",
    'Well, wow. It was bad. But I have space now. ',
    "I'm running. Hi, I'm so sorry. I'm late. ",
    'for our final prize. ',
    'five pieces,',
    "And that's what I treasure. Most. ",
    'What does it mean? What does it brings to us? What does it mean, in this specific cost? I mean, I feel that there is a level of difficulty in articulating all these things, especially through this modality of communication, which are really painful for me, but they are what they are',
  ],
  [
    "that substitute for some lightness of the relationship, that is not happening. Right. I feel like it'd be really nice to allow people to be part of it. And then if two people from east and west coast, or even better,",
    "it's been a little tricky getting a hold of them",
    'we made a giant desk in the middle of these mountains. ',
    "So you're not exactly yourself, but you can actually interact with other people. ",
    "So it's like, holy, when Yeah, one lady, she did this",
    'say hi, and celebrate a birthday by building a cake out of blocks and stuff. ',
    'dead drops.',
    "Yeah. Like how you can embed a video? Can you do that now streaming videos, embed it? secondary site? I have no idea. What I've been doing, if I want to save the experience is I just screen capture the entire read.",
  ],
  [
    'in terms of viruses? Like if using a certain computer, can it be problematic for the next person that uses it? Or no?',
    'If you want to see like examples of what',
    'See?',
    'Oh, here we go.',
    'I think I just downloaded a virus just',
    'not starting well. ',
    'Is it working for anyone else?',
    "You can hang this one from anywhere. Even if they get wet. They don't really know. ",
  ],
  [
    'I want to embed it on the Sunshine Coast, where right now in the forest,',
    'garden across the street, I really want to go there and put it there? ',
    "Well, I think there's a there's something interesting about us, you know, for all of us starting a mystery about this thing, ",
    'And also it sounds like the muzzle is a bit of a mapping',
    'creating some sort of, like lineage',
  ],
  [
    "I'm talking about the room. ",
    'Like the room muscle room',
    'Blowing up. Horses, I guess the materials could be the same, ',
    "And this year, because they couldn't do burning men, they actually did something like that. ",
    'We have our own strain. ',
    'language a virus',
    "take off your shoes, here's the house rules.",
  ],
  [
    'Not too heavy material?',
    'Yeah. I mean, just mean?',
    'We wrote to find the vaccine for this one, we need to boost it up.',
    'Dead drops',
  ],
  [
    "But, but the visibility of that always, and the success of those interaction is always depending on how people come in on wire because it's just too right working is not connection, or is poor connection, and so forth. So So I mean, if people know that they will, you know, decide if they want to join,",
    'And be an expert. Just act as like an external guest to the room and see how does it feel ',
    'I have a question about unmuted. No, can you hear me? Yeah. Okay. Um, I guess I have a question. ',
    '1241231221 hour, two hours, so that people from the east and the west can join in?',
    'My whole day is free. ',
    'The chamber, the virtual experience',
    "I don't know because I've thought What about We walk up both those days. But you know what, if you guys, I can always talk to somebody afterwards,",
    'Thank you. Thank you.',
  ],
  [
    'Lorem ipsum dolor sit amet,',
    'consectetur adipiscing elit,',
    'sed tempor and vitality, so that the labor and sorrow,',
    'some important things to do eiusmod.',
    'In the case of a minimum of water, the exercise is performed in the laboratory and the test is performed.',
    'Want to be a pain in the cupidatat cillum has been criticized',
    'in the Duis et dolore magna flee produces no resultant pleasure.',
    'Excepteur cupidatat occaecat are not excepteur,',
    'is soothing to the soul, that is, they are my toil,',
    'they deserted the general is to blame that services should be',
  ],
  [
    'Badla: Hi, Ai Nahi Ho',
    'Ace Fiara Jay Piraiye Comey Mami Tama Toon Lukas',
    'This is the new phaṛanā thōṛā',
    'It is pā if you still have',
  ],
  'Badla: Hi, Ai Nahi Ho',
  'Ace Fiara Jay Piraiye Comey Mami Tama Toon Lukas',
  'This is the new phaṛanā thōṛā',
  'It is pā if you still have',
  'anything you give away today, or something has ever happened before',
  'But there are two people from east to west, or more',
  'he did a little trick to get them',
  'So you are not alone, but you can work with others.',
  'It seems holy yes, woman, when she did',
  'Greet and celebrate birthdays by writing cakes with blocks and other objects.',
  'Silent death drops.',
  [
    'The second book? I do not know. All I have to do is save the information.',
    'we are not.',
    'If it is protein?',
    'If you want to see any examples',
    "People do voice. Someone put it on the dog's collar.",
    "Oh, let's go.",
    'I think I just signed up for the review',
    'The start was not smooth.',
  ],
  'You can hang it from anywhere. Even if it gets wet. They don’t really know.',
  'I would like to include it on the sunny coast. Now it is in the forest.',
  'Also, the puzzle looks somewhat mapped',
  'Create a kind of lineage',
  [
    'Take off your shoes, here are the house rules.',
    "Isn't it heavy? -Yes. I think I think so?",
    'We wrote it should be strengthened.',
    'Fire and mapping.',
    'Dead spots',
  ],
  'But it always seems and the success of that interaction always depends on how people climb',
  'the wire, because it is very true that it is not a relationship or a bad relationship. So, if people',
  'know this, you have decided to join.',
  '1241231221 Hours, two hours, east and west in?',
  'I left one day.',
  'Space, a clear experience',
  'I was wondering how I could end these two days. But you know what? As a human being, I can',
  'Thank you all.',
  'Thank you thank you',
  [
    'I conclude. I think it must be at 1 in the morning, but we can start if you want.',
    'We can start.',
    'We can delete it with some frameworks',
    'It is this snake that has a tail.',
    'From macro tumors to microscope points.',
    'Radio stuff',
  ],
  'Yeah Al that sounds pretty crap to me, Looks like BT aint for me either.',
  'If the kaleidoscopic angle is large',
  'If the calendar is small, there is no black and white',
  [
    'Listen to each other',
    'Like fire in another bucket of water',
    "I don't know",
    'The pleasure is all mine.',
    "I don't think people can hide his name",
    'Wash your face',
    'So we are done with the memory',
    'If we want more than that, we are in pain.',
    'it can be done at any time, if we can.',
  ],
  'I do not have a specific diet or I have to do it one way or another.',
  'Sorry, I missed you a little.',
  "Thanks. That's all. Something. Thanks.",
  'This is not correct.',
  'This is me. So I see no reason to try.',
  'This is a conversation. In other chord forms, it appears in other ways and develops people’s',
  'minds.',
  'I like Robin Hood here.',
  "Yes, but you fit, I'll put you back in the sun.",
  'I have to spend many days on many forms.',
  'What is it called? Luni Besides?',
  'The fog is unbalanced.',
  'It was good. All rights reserved.',
  'I think this guy can help, because it helps',
  'That is where the foundation is not.',
  'The scent is not Scorpio',
  'So why look',
  "I am everyone's best friend.",
  'You can only see this in the room.',
  'Which room do we need?',
  'If we spend some time in the room, maybe',
  'So I kept quiet',
  "Can't wait, what was that?",
  'Because now you can see it, like you were in a room, you can see it. There is something',
  "I don't know who he is or what he is, but I listen to myself",
  'Is everything in the room now?',
  'I have a very difficult time',
  'I think you think you can join',
  "I don't know if he will give it to you.",
  'But don’t expect that from you. This may take some time. Thanks.',
  'Are you really saying that?',
  'People can’t just take it.',
  'here we speak',
  'Someone sends.',
  'We are exhausted.',
  [
    'The basket in the background I may fly the plane allows.',
    'There is a small magic wand.',
    'Another piece of fabric on the ground',
    'there is a plane',
    'About 10 minutes could have lost it. and so;',
    'We all write the same thing',
  ],
  'Sounds like a harp or a drum.',
  'So I don’t know if you make salmon that many producers have',
  [
    'Type of comparison',
    'What do you do all the time?',
    'Home placement.',
    'Because you can go into the unknown',
    'We tried the playground outside.',
  ],
  'Corner of the stairs',
  ['(Yes.)', '(Yes.)', '(Yes.)'],
  "I'm almost sorry. Sorry about that, and we didn't know what it was for a month.",
  'cualquier cosa que regale hoy, o algo que haya sucedido antes',
  'No es bueno. Pero ahora tengo un lugar.',
  'Y eso es lo que cuento. por lo tanto cierto',
  'Pero hay dos personas de este a oeste, o más',
  'hizo un pequeño truco para conseguirlos',
  'hicimos una gran mesa en medio de estas montañas.',
  [
    'Así que no estás solo, pero puedes trabajar con otros.',
    'Parece santo si, mujer, cuando lo hizo',
    'Gotas de muerte silenciosa.',
    '¿El segundo libro? No lo sé. Todo lo que tengo que hacer es guardar la información.',
    'no somos.',
    'Puedes colgarlo desde cualquier lugar. Incluso si se moja. Realmente no lo saben.',
    'Jardín al otro lado de la calle, ¿de verdad quieres ir y ponerlo allí?',
    'Bien, creo que todos estamos desentrañando el misterio sobre esto.',
    'Además, el rompecabezas parece algo mapeado',
    'Crea una especie de linaje',
    'Quítese los zapatos, aquí están las reglas de la casa.',
  ],
  'No, no, no, no, creo que tienes razón',
  [
    'Lo escribimos debería fortalecerse.',
    'Fuego y cartografía.',
    'Puntos muertos',
    'Pero siempre parece y el éxito de esa interacción siempre depende de cómo la gente se suba',
  ],
  'al cable, porque es muy cierto que no es una relación ni una mala relación. Entonces, si la',
  'gente sabe esto, ha decidido unirse.',
  [
    '1241231221 Horas, dos horas, este y oeste en?',
    'Espacio, una experiencia clara',
    'Me preguntaba cómo podría terminar estos dos días. ¿Pero sabes que? Como ser humano,',
    'ciertamente puedo decir uno por uno.',
    'Yo concluyo. Creo que debe ser a la 1 de la madrugada, pero podemos empezar si quieres.',
    'Podemos empezar.',
    'Podemos eliminarlo con algunos frameworks.',
    'Es esta serpiente la que tiene cola.',
  ],
  'Así que no sé si alguna vez podremos ponernos en contacto con él.',
  'Sí Al, eso me suena bastante mal, parece que BT tampoco es para mí.',
  'Si el ángulo caleidoscópico es grande',
  'No espere a que el poder de la bala se agote de este grupo.',
  [
    'Si el calendario es pequeño, no hay blanco y negro.',
    'Creo que es divertido, bueno, bueno, no bueno ahora.',
    'Sí, pero encajas, te volveré a poner al sol.',
    'Tengo que pasar muchos días en muchas formas.',
    'Como se llama ¿Luni además?',
    'La niebla está desequilibrada.',
    'Estuvo bien. Todos los derechos reservados.',
    'Adiós. Ten cuidado. Adiós. Adiós. Adiós. Gracias',
    'Creo que este chico puede ayudar, porque ayuda.',
  ],
  'El no me deja entrar',
  'Creo que crees que puedes unirte',
  'No sé si te lo dará.',
  'Pero no espere eso de usted. Esto puede tomar algo de tiempo. Gracias.',
  'Diferentes botones y hebillas.',
  'Alguien envía.',
  'Estamos exhaustos.',
  'La canasta en el fondo puedo volar el avión lo permite.',
  'Hay una pequeña varita mágica.',
  'asombroso',
  [
    'Otro trozo de tela en el suelo',
    'hay un avion',
    'Aproximadamente 10 minutos podrían haberlo perdido. y entonces;',
    'Todos escribimos lo mismo',
    'Suena como un arpa o un tambor.',
    'Así que no sé si elabora salmón que muchos productores tienen',
    'Tipo de comparacion',
  ],
  [
    '¿Qué haces todo el tiempo?',
    'Puedes sentirlo por dentro. Pero si quieres, vete.',
    'Colocación en el hogar.',
    'Entonces les gusta tener un modelo preconfigurado',
    'Probamos el patio de recreo afuera.',
    'Esquina de las escaleras',
  ],
  '¿Qué hacen las personas que todavía creen?',
  ['(Si.)', '(Si.)', '(Si.)'],
  'Ahora todos amamos, eso es todo.',
  'Casi lo siento.',
  'Lo siento, y no supimos qué era durante un mes.',
];
