import React, { useEffect, useState } from 'react';
import prng from '../../classes/PRNG';
import SETTINGS from '../../globals/settings';
import { useRandomMeter } from '../../hooks/useRandomMeter';
import { WidgetComponent, WidgetProps } from '../../types/widget';
import { Bars } from './bars';
import { NestedSquares } from './nestedSquares';
import { Squares } from './squares';
import './style.css';

export interface InnerProps extends WidgetProps {
  values: number[];
}

export const MeterThing: WidgetComponent = (props) => {
  const [value, setValue] = useState(0);
  const [value2, setValue2] = useState(0);
  const [value3, setValue3] = useState(0);
  const [value4, setValue4] = useState(0);
  const [version, setVersion] = useState(0);

  useEffect(() => {
    setVersion(Math.floor(prng.float() * 3));
  }, []);

  useRandomMeter(setValue);
  useRandomMeter(setValue2);
  useRandomMeter(setValue3);
  useRandomMeter(setValue4);
  return (
    <div
      className="MeterThingOuter"
      onClick={() => setVersion((v) => (v + 1) % 3)}
    >
      {version === 0 && (
        <Bars {...props} values={[value, value2, value3, value4]} />
      )}
      {version === 1 && (
        <Squares {...props} values={[value, value2, value3, value4]} />
      )}
      {version === 2 && (
        <NestedSquares {...props} values={[value, value2, value3, value4]} />
      )}
    </div>
  );
};

MeterThing.widgetName = 'MeterThing';
MeterThing.sizes = () => {
  const isFeatured =
    SETTINGS.SONG.BLOCK_WIDGETS && SETTINGS.SONG.BLOCK_WIDGETS.length > 6;
  if (isFeatured)
    return [
      [1, 2],
      [2, 3],
      [2, 2],
      [3, 3],
      [3, 4],
      [3, 5],
      [4, 4],
      [4, 5],
      [5, 5],
    ];
  else
    return [
      [1, 1],
      [1, 2],
      [1, 3],
      [2, 3],
      [2, 2],
      [3, 3],
      [3, 4],
    ];
};
