import React from 'react';
import './style.css';
import C from '../../globals/constants';
import Img from './play-circle.svg';

export const ClickToBegin = (props: { onClick: () => void }) => {
  return (
    <div
      style={{
        width: `${C.NUM_COLS * C.BOX_WIDTH}px`,
        height: `${C.NUM_ROWS * C.BOX_WIDTH}px`,
      }}
      className="ClickToBeginOuter"
    >
      <button className="ClickToBegin" onClick={props.onClick}>
        <img src={Img} alt="about" />
      </button>
    </div>
  );
};
