import { shuffleArray } from '../../utils';
import G from '../globals';
import cantoneseTexts from './cantonese';
import frenchTexts from './french';
import italianTexts from './italian';
import oldTexts from './old';
import spanishTexts from './spanish';

let texts = shuffleArray([
  ...oldTexts,
  ...italianTexts,
  ...frenchTexts,
  ...cantoneseTexts,
  ...spanishTexts,
]);

export const getNextText = () => {
  const val = texts[G.CUR_TEXT_IDX];
  G.CUR_TEXT_IDX = (G.CUR_TEXT_IDX + 1) % texts.length;

  if (G.CUR_TEXT_IDX === 0) {
    texts = shuffleArray(texts);
  }
  return val;
};
