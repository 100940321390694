const getNumColumns = () => {
  const size = document.body.clientWidth;
  if (size > 1000) return 8;
  if (size > 800) return 7;
  if (size > 500) return 6;
  return 5;
};

const getNumRows = () => {
  const size = document.body.clientWidth;
  if (size > 1000) return 5;
  if (size > 700) return 6;
  if (size > 500) return 7;
  return 8;
};

const getBoxWidth = () => {
  const min = 60;
  const max = 150;
  const calculated = document.body.clientWidth / getNumColumns();
  return Math.max(min, Math.min(max, calculated)) - getNumColumns();
};

const getBoxMargin = () => getBoxWidth() * 0.2;

const C = {
  NUM_ROWS: getNumRows(),
  NUM_COLS: getNumColumns(),
  BOX_WIDTH: getBoxWidth(),
  BOX_MARGIN: getBoxMargin(),
};

export default C;
