import React from 'react';
import { WidgetComponent } from '../../types/widget';
import { Image } from '../Image';

export const PlaceHolder: WidgetComponent = (props) => {
  return <Image {...props} />;
};
PlaceHolder.widgetName = 'PlaceHolder';

PlaceHolder.sizes = [[1, 1]];
