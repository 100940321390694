import React, { useMemo } from 'react';
import { Slider as NexusSlider } from 'react-nexusui';
import C from '../../globals/constants';
import { useColorizeOnReady } from '../../hooks/useColorizeOnReady';
import { useScalarWidgetParam } from '../../hooks/useScalarWidgetParam';
import { WidgetComponent } from '../../types/widget';

interface SliderProps {
  size: number[];
  fillColor: string;
  accentColor: string;
}

const Slider = (props: SliderProps) => {
  const { onChange, min, max, value } = useScalarWidgetParam();

  const onReady = useColorizeOnReady(props.fillColor, props.accentColor);
  return (
    <NexusSlider
      size={[props.size[0], props.size[1]]}
      max={max}
      min={min}
      value={value}
      onChange={onChange}
      onReady={onReady}
    />
  );
};
export const Sliders: WidgetComponent = (props) => {
  const nSliders = Math.floor(props.rows * 1.5);
  const dummyArray = useMemo(() => Array(nSliders).fill(0), [nSliders]);
  const size = [
    props.width - C.BOX_MARGIN * 1.5,
    ((props.height - C.BOX_MARGIN) / nSliders) * 0.6,
  ];
  return (
    <>
      {dummyArray.map((_, i) => (
        <Slider
          size={size}
          key={i}
          fillColor={props.fillColor}
          accentColor={props.accentColor}
        />
      ))}
    </>
  );
};
Sliders.widgetName = 'Sliders';
Sliders.sizes = [
  [1, 3],
  [3, 2],
  [1, 4],
  [1, 2],
  [2, 1],
  [3, 3],
  [2, 2],
];
