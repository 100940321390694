import React, { useCallback } from 'react';
import { Position as NexusPosition } from 'react-nexusui';
import C from '../../globals/constants';
import { useColorizeOnReady } from '../../hooks/useColorizeOnReady';
import { useScalarWidgetParam } from '../../hooks/useScalarWidgetParam';
import { WidgetComponent } from '../../types/widget';

export const Position: WidgetComponent = ({
  fillColor,
  accentColor,
  width,
  height,
}) => {
  const {
    onChange: onChangeX,
    min: minX,
    max: maxX,
    value: valueX,
  } = useScalarWidgetParam();
  const {
    onChange: onChangeY,
    min: minY,
    max: maxY,
    value: valueY,
  } = useScalarWidgetParam();
  const onChange = useCallback(
    ({ x, y }: { x: number; y: number }) => {
      if (onChangeX) onChangeX(x);
      if (onChangeY) onChangeY(y);
    },
    [onChangeX, onChangeY],
  );

  const onReady = useColorizeOnReady(fillColor, accentColor);
  return (
    <NexusPosition
      minX={minX}
      maxX={maxX}
      minY={minY}
      maxY={maxY}
      x={valueX}
      y={valueY}
      size={[width - C.BOX_MARGIN, height - C.BOX_MARGIN]}
      onChange={onChange}
      onReady={onReady}
    />
  );
};

Position.widgetName = 'Position';
Position.sizes = [
  [2, 2],
  [2, 3],
  [2, 3],
  [1, 1],
  [1, 2],
  [2, 1],
];
