import * as Tone from 'tone';
import { getLoopOutput } from '../../../utils';
import { getRandomNumberList, volParamToDecibels } from '../../../utils/params';
import { ParamPubSub } from '../../ParamPubSub';
import ChangeController from '../ChangeController';
import { subscribeController } from '../subscribeController';
import PoolController from './Pool';
import { getLoopParams, loopInitialValues } from './loopParams';
import { loopSubscriptions } from './subscriptions';

const DEFAULT_VIBRATO_FREQ = 2.2;

export default class LoopController extends ChangeController {
  pool: PoolController;
  nodes: {
    vibrato: Tone.Vibrato;
    pitch: Tone.PitchShift;
    miscEffect:
      | Tone.Distortion
      | Tone.BitCrusher
      | Tone.Phaser
      | Tone.Reverb
      | Tone.AutoWah
      | undefined;
  };
  name: string;
  pubSub: ParamPubSub;
  updateOutput: (items: string[]) => void;
  unSubscribeEvents?: () => void;

  reverse = false;
  loopiness = loopInitialValues.loopiness;
  // for looping through scalarLists
  currentBeat = 0;
  // combined params:
  baseVolume = loopInitialValues.baseVolume;
  volumeBoostList = getRandomNumberList(-4.2, 0);
  baseSpeed = loopInitialValues.baseSpeed;
  speedBoostList = getRandomNumberList(-0.2, 0.2);

  constructor(
    filenames: string[],
    loopName: string,
    pubSub: ParamPubSub,
    updateOutput: (items: string[]) => void,
  ) {
    super();
    this.updateOutput = updateOutput;

    this.pool = new PoolController(filenames);
    this.pool.setVolume(loopInitialValues.baseVolume);

    this.nodes = {
      pitch: new Tone.PitchShift(0),
      vibrato: new Tone.Vibrato(DEFAULT_VIBRATO_FREQ, 0),
      miscEffect: undefined,
    };

    // create misc effect
    const r = Math.random() * 5;

    if (r < 1) {
      this.nodes.miscEffect = new Tone.Distortion(0);
    } else if (r < 2) {
      this.nodes.miscEffect = new Tone.BitCrusher(16);
    } else if (r < 3) {
      this.nodes.miscEffect = new Tone.Phaser(0, 1 + Math.random() * 6, 200);
    } else if (r < 4) {
      this.nodes.miscEffect = new Tone.Reverb(0.5 + Math.random() * 7);
    } else {
      this.nodes.miscEffect = new Tone.AutoWah(
        10 + Math.random() * 300,
        3 + Math.random() * 5,
        -40 + Math.random() * 20,
      );
      this.nodes.miscEffect.Q.value = 9;
    }

    // pool -> pitchshift -> miscEffect -> vibrato -> output
    this.pool.connectOutput(this.nodes.pitch);
    this.nodes.pitch.connect(this.nodes.miscEffect);
    this.nodes.miscEffect?.connect(this.nodes.vibrato);
    this.name = loopName;
    this.pubSub = pubSub;
  }

  connectOutput = (node: Tone.ToneAudioNode) =>
    this.nodes.vibrato.connect(node);

  getParams = () => {
    return getLoopParams(
      this.name,
      this.speedBoostList,
      this.volumeBoostList,
      this.pool.nodes.panVol.pan.value,
    );
  };

  updateSpeedValue = () => {
    const boostVal = this.speedBoostList[this.currentBeat] || 0;
    const speed = Math.pow(2, this.baseSpeed + boostVal);
    this.pool.setSpeed(speed);
  };

  updateVolumeValue = () => {
    const boostVal = this.volumeBoostList[this.currentBeat] || 0;
    this.pool.setVolume(volParamToDecibels(this.baseVolume + boostVal));
  };

  updateToBeat = (beat: number) => {
    this.currentBeat = beat;
    this.updateSpeedValue();
    this.updateVolumeValue();
  };

  dispose = () => {
    this.clearAllTimeoutsAndIntervals();
    this.unSubscribeEvents && this.unSubscribeEvents();
    this.pool.dispose();
    this.nodes.pitch.dispose();
    this.nodes.vibrato.dispose();
  };
  //// subscriptions ======================= //
  subscribeEvents = () => {
    this.unSubscribeEvents = subscribeController(
      this,
      this.pubSub,
      loopSubscriptions,
      () => this.updateOutput(getLoopOutput(this)),
    );
  };
}
