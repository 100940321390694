import React, { useMemo } from 'react';
import C from '../../globals/constants';
import { useWidgetColors } from '../../hooks/useWidgetColors';
import { BoxData } from '../../types/box';
import { getWidgetOfSize } from '../../utils/widgets';
import './styles.css';

export function Widget(props: BoxData) {
  const WidgetComponent = useMemo(() => {
    return getWidgetOfSize(props.size);
  }, [props.size]);
  const { backgroundColor, fillColor, accentColor } = useWidgetColors();
  return (
    <div className="widget">
      <div className="widgetContent" style={{ backgroundColor }}>
        <WidgetComponent
          rows={props.size.rows}
          columns={props.size.columns}
          height={props.size.rows * C.BOX_WIDTH}
          width={props.size.columns * C.BOX_WIDTH}
          widgetId={props.id}
          fillColor={fillColor}
          accentColor={accentColor}
        />
      </div>
    </div>
  );
}
