import { BoxData, BoxSize } from '../types/box';
import { checkIfWidgetOfSizeExists } from '../utils/widgets';
import prng from './PRNG';
import { SwapManager } from './SwapManager';

const splitUnits = (units: number) => {
  const divider = Math.round(prng.float() * (units - 2)) + 1;
  return [divider, units - divider];
};

let _lastId = 0;
const getId = () => {
  _lastId++;
  return `${_lastId}`;
};

export class BoxTree {
  size: BoxSize;
  manager: SwapManager;
  children: BoxTree[];
  id: string;
  constructor(size: BoxSize, manager: SwapManager) {
    this.size = size;
    this.manager = manager;
    this.children = [];
    this.id = getId();
    if (size.columns > 1 || size.rows > 1) {
      this.manager.register(this.id, {
        swap: () => this.reset(),
      });
    }
    this.reset();
  }

  private isLeaf = () => {
    const canBeLeaf =
      checkIfWidgetOfSizeExists(this.size) ||
      (this.size.columns === 1 && this.size.rows === 1);
    return this.children.length === 0 && canBeLeaf;
  };

  private shouldBecomeLeafBox = () => {
    const { columns, rows } = this.size;
    // can't split less than 1x1
    if (rows <= 1 && columns <= 1) return true;

    const avgDimension = (columns + rows) / 2;
    if (
      prng.float() > avgDimension * 0.1 &&
      checkIfWidgetOfSizeExists(this.size)
    )
      return true;
    // split into more boxes
    return false;
  };

  private getRowChildSizes = () => {
    const rowPartitions = splitUnits(this.size.rows);
    let total = 0;
    return rowPartitions.map((r) => {
      const offsetRows = total + this.size.offsetRows;
      const offsetColumns = this.size.offsetColumns;
      total += r;
      return { rows: r, columns: this.size.columns, offsetColumns, offsetRows };
    });
  };
  private getColumnChildSizes = () => {
    const colPartitions = splitUnits(this.size.columns);
    let total = 0;
    return colPartitions.map((c) => {
      const offsetColumns = total + this.size.offsetColumns;
      const offsetRows = this.size.offsetRows;
      total += c;
      return { rows: this.size.rows, columns: c, offsetColumns, offsetRows };
    });
  };

  private getNewChildSizes = () => {
    const { columns, rows } = this.size;
    if (this.shouldBecomeLeafBox()) return [];

    if (rows > columns) return this.getRowChildSizes();
    else if (columns > rows) return this.getColumnChildSizes();
    else {
      if (prng.float() > 0.5) return this.getRowChildSizes();
      else return this.getColumnChildSizes();
    }
  };

  reset = () => {
    const wasLeaf = this.isLeaf();
    this.children.forEach((child) => {
      child.unregister();
    });
    const childSizes = this.getNewChildSizes();

    this.children = childSizes.map((size) => new BoxTree(size, this.manager));
    const didSwap = !(wasLeaf && this.isLeaf());
    return didSwap;
  };

  unregister = () => {
    this.children.forEach((child) => {
      child.unregister();
    });
    this.manager.unregister(this.id);
  };

  getLeafData = (): BoxData[] => {
    if (this.isLeaf())
      return [
        {
          size: this.size,
          id: this.id,
        },
      ];
    return this.children.reduce<BoxData[]>(
      (prev, cur) => prev.concat(cur.getLeafData()),
      [],
    );
  };
}
