import { BeatVisual } from './BeatVisual';
import { BigDial } from './BigDial';
import { Button } from './Button';
import { Dials } from './Dials';
import { Image } from './Image';
import { MeterThing } from './MeterThing';
import { MultiSlider } from './MultiSlider';
import { Position } from './Position';
import { Sequencer } from './Sequencer';
import { Sliders } from './Sliders';
import { SwitchSong } from './SwitchSong';
import { Text } from './Text';

export type WidgetName =
  | 'Button'
  | 'BigDial'
  | 'Dials'
  | 'Image'
  | 'MultiSlider'
  | 'MeterThing'
  | 'Position'
  | 'Sequencer'
  | 'Sliders'
  | 'Text'
  | 'BeatVisual'
  | 'PlaceHolder'
  | 'TriggerButton'
  | 'SwitchSong';

const widgets = [
  Button,
  BigDial,
  Dials,
  Image,
  MultiSlider,
  MeterThing,
  Position,
  Sequencer,
  Sliders,
  Text,
  BeatVisual,
  SwitchSong,
  // Placeholder isn't here because it's a fallback option
];

export default widgets;
