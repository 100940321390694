import React from 'react';
import C from '../../globals/constants';
import { InnerProps } from '.';
import './style.css';

export const Bars = ({ width, height, accentColor, values }: InnerProps) => {
  const meterWidth = width - C.BOX_MARGIN;
  const meterHeight = ((height - C.BOX_MARGIN) / 4) * 0.95;
  return (
    <>
      <div
        className="MeterThingBar"
        style={{
          transform: `scale(${Math.max(0.01, (values[0] + 60) / 50)}, 1)`,
          height: meterHeight,
          width: meterWidth,
          background: accentColor,
          opacity: 0.6,
        }}
      />
      <div
        className="MeterThingBar"
        style={{
          transform: `scale(${Math.max(0.01, (values[1] + 60) / 50)}, 1)`,
          height: meterHeight,
          width: meterWidth,
          background: accentColor,
          opacity: 0.6,
        }}
      />
      <div
        className="MeterThingBar"
        style={{
          transform: `scale(${Math.max(0.01, (values[2] + 60) / 50)}, 1)`,
          height: meterHeight,
          width: meterWidth,
          background: accentColor,
          opacity: 0.6,
        }}
      />
      <div
        className="MeterThingBar"
        style={{
          transform: `scale(${Math.max(0.01, (values[3] + 60) / 50)}, 1)`,
          height: meterHeight,
          width: meterWidth,
          background: accentColor,
          opacity: 0.6,
        }}
      />
    </>
  );
};
