import React, { useContext } from 'react';
import { animated, useTransition } from 'react-spring';
import C from '../../globals/constants';
import { ContextSwapManager } from '../ContextSwapManager';
import { Widget } from '../Widget';
import './styles.css';

export function Swapper() {
  const { boxes, updateNumber } = useContext(ContextSwapManager);

  const transitions = useTransition(boxes, (data) => data.id, {
    from: {
      position: 'absolute',
      transform: 'scale(0.7)',
      opacity: 0,
    },
    enter: { transform: 'scale(1)', opacity: 1 },
    leave: { transform: 'scale(0)', opacity: 0 },
    config: (item) => ({
      friction: 12.5,
      mass: 1 + (item.size.columns * item.size.rows) / 10,
      tension: 100,
    }),
  });
  return (
    <div
      className="SwapperOuter"
      style={{
        width: C.NUM_COLS * C.BOX_WIDTH,
        height: C.NUM_ROWS * C.BOX_WIDTH,
      }}
    >
      {transitions.map((t) => (
        <animated.div
          key={t.key}
          style={{
            ...t.props,
            position: 'absolute',
            width: `${t.item.size.columns * C.BOX_WIDTH}px`,
            height: `${t.item.size.rows * C.BOX_WIDTH}px`,
            top: `${t.item.size.offsetRows * C.BOX_WIDTH}px`,
            left: `${t.item.size.offsetColumns * C.BOX_WIDTH}px`,
          }}
        >
          <Widget {...t.item} />
        </animated.div>
      ))}
      <span className="hidden">{updateNumber}</span>
    </div>
  );
}
