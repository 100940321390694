import React, { createContext, useEffect, useMemo, useState } from 'react';
import G from '../../globals/globals';
import './styles.css';

interface ContextTestOutputValue {
  updateOutput: (items: string[]) => void;
}

export const ContextTestOutput = createContext<ContextTestOutputValue>({
  updateOutput: () => {
    return;
  },
});

export function TestOutput(props: React.PropsWithChildren<{}>) {
  const [items, setItems] = useState<string[]>([]);
  const [visible, setVisible] = useState(false);
  const [active, setActive] = useState(false);

  const contextValue = useMemo(
    () => ({
      updateOutput: setItems,
    }),
    [],
  );

  useEffect(() => {
    setVisible(true);

    const timeoutId = window.setTimeout(() => {
      setVisible(false);
    }, 1000);

    return () => {
      window.clearTimeout(timeoutId);
    };
  }, [items]);

  return (
    <>
      <div
        className="TestOutput"
        style={{ display: G.TEST && visible && active ? 'initial' : 'none' }}
      >
        {items.map((n, i) => (
          <div key={i} className="TestOutputItem">
            {n}
          </div>
        ))}
      </div>
      <button
        onClick={() => setActive((a) => !a)}
        className="activateTestOutputButton"
        style={{
          display: G.TEST ? 'initial' : 'none',
          position: 'absolute',
          zIndex: 1000,
        }}
      >
        {active ? 'HIDE TESTING INFO' : 'SHOW TESTING INFO'}
      </button>
      <ContextTestOutput.Provider value={contextValue}>
        {props.children}
      </ContextTestOutput.Provider>
    </>
  );
}
