import { FileManifest, ImageManifest } from '../types/fileManifest';
import { shuffleArray } from '../utils';
import fileManifest from './fileManifest.json';
import G from './globals';

const visuals = (fileManifest as FileManifest).visuals;
export const imageManifest: ImageManifest = {
  visuals: shuffleArray(visuals),
};

export const getNextImage = () => {
  const val = imageManifest.visuals[G.CUR_IMG_IDX];
  G.CUR_IMG_IDX = (G.CUR_IMG_IDX + 1) % imageManifest.visuals.length;
  if (G.CUR_IMG_IDX === 0) {
    imageManifest.visuals = shuffleArray(imageManifest.visuals);
  }
  return val;
};
