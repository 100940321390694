import { Param } from '../types';

export const loopInitialValues = {
  baseVolume: -1.6,
  baseSpeed: 0,
  loopiness: 0.5,
};
export const getLoopParams = (
  loopName: string,
  speedBoostList: number[],
  volumeBoostList: number[],
  initialPan: number,
): Param[] => [
  {
    type: 'scalar',
    name: `${loopName}_baseVolume`,
    min: -7.5,
    max: 0,
    val: loopInitialValues.baseVolume,
  },
  {
    type: 'scalar',
    name: `${loopName}_baseSpeed`,
    min: -0.7,
    max: 0.7,
    val: loopInitialValues.baseSpeed,
  },
  {
    type: 'scalar',
    name: `${loopName}_vibratoDepth`,
    min: 0,
    max: 1,
    val: 0,
  },
  {
    type: 'scalar',
    name: `${loopName}_pitchShift`,
    min: -5,
    max: 5,
    val: 0,
  },
  {
    type: 'scalar',
    name: `${loopName}_miscEffect`,
    min: 0,
    max: 1,
    val: 0,
  },
  {
    type: 'boolean',
    name: `${loopName}_reverse`,
    val: false,
  },
  {
    type: 'scalarList',
    name: `${loopName}_speedBoostList`,
    maxRandomSubscribers: 2,
    min: -0.5,
    max: 0.5,
    val: speedBoostList,
  },
  {
    type: 'scalarList',
    name: `${loopName}_volumeBoostList`,
    maxRandomSubscribers: 2,
    min: -4.2,
    max: 0,
    val: volumeBoostList,
  },
  {
    type: 'scalar',
    name: `${loopName}_loopiness`,
    min: 0,
    max: 1,
    val: loopInitialValues.loopiness,
  },
  {
    type: 'scalar',
    name: `${loopName}_pan`,
    min: -1,
    max: 1,
    val: initialPan,
  },
];
