import React, { useEffect, useMemo, useState } from 'react';
import prng from '../../classes/PRNG';
import { getNextText } from '../../globals/texts';
import { WidgetComponent, WidgetProps } from '../../types/widget';
import './style.css';

const fontFamilies = [
  "source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace",
  "'Cairo', sans-serif",
  "'Oswald', sans-serif",
  'serif',
];
const getFontFamily = () => {
  return fontFamilies[Math.floor(prng.float() * fontFamilies.length)];
};
const getFontWeight = () => {
  return Math.floor(prng.float() * 4) * 100 + 300;
};
const getFontOpacity = () => {
  return 0.5 + prng.float() * 0.5;
};
const splitIntoSubTexts = (txt: string) => {
  const result: string[] = [];
  let idx = 0;
  while (idx < txt.length) {
    const prevIdx = idx;
    idx = idx + 1 + Math.floor(prng.float() * 18);
    result.push(txt.slice(prevIdx, idx));
  }
  return result;
};
const getTextLengthAdjust = (charCount: number) => {
  // long texts
  if (charCount > 200) return 0.5;
  if (charCount > 140) return 0.65;
  if (charCount > 100) return 0.75;
  if (charCount > 75) return 0.84;
  if (charCount > 50) return 0.9;
  // short texts
  if (charCount < 10) return 2;
  if (charCount < 17) return 1.5;
  if (charCount < 25) return 1.2;
  return 1;
};

const RenderText = ({
  width,
  height,
  accentColor,
  text,
}: WidgetProps & { text: string }) => {
  const fontColor = useMemo(
    () => [accentColor, '#FFF'][Math.floor(prng.float() * 2)],
    [accentColor],
  );

  const textSizeRange = useMemo(() => 0.3 + prng.float() * 0.7, []);

  const spaceFactor = Math.pow(width * 1.5 * (height * 0.5), 0.7);
  const textLengthAdjust = getTextLengthAdjust(text.length);

  const subTexts = useMemo(
    () =>
      splitIntoSubTexts(text).map((txt) => {
        const fontSize =
          spaceFactor *
          0.013 *
          textLengthAdjust *
          (1 - textSizeRange / 2 + prng.float() * textSizeRange);
        return {
          txt,
          style: {
            fontSize,
            fontFamily: getFontFamily(),
            fontWeight: getFontWeight(),
            opacity: getFontOpacity(),
          },
        };
      }),
    [spaceFactor, text, textLengthAdjust, textSizeRange],
  );

  return (
    <div className="widgetTexts" style={{ color: fontColor }}>
      {subTexts.map((item, i) => (
        <span key={i} style={item.style} className="widgetSingleText">
          {item.txt}
        </span>
      ))}
    </div>
  );
};

const getStartAndEndIdx = (txt: string[]) => {
  if (txt.length > 3) {
    const startPoint = prng.float();
    const endPoint = prng.float() * (1 - startPoint) + startPoint;
    const startIdx = Math.floor(startPoint * txt.length);
    const endIdx = Math.floor(endPoint * txt.length);
    return {
      startIdx,
      endIdx,
    };
  } else {
    return {
      startIdx: 0,
      endIdx: txt.length - 1,
    };
  }
};
export const Text: WidgetComponent = (props) => {
  const [text, setText] = useState<string | string[]>('');
  const [textIdx, setTextIdx] = useState(0);
  const [endIdx, setEndIdx] = useState(0);

  useEffect(() => {
    const txt = getNextText();
    setText(txt);
    if (Array.isArray(txt)) {
      const { startIdx, endIdx } = getStartAndEndIdx(txt);
      setTextIdx(startIdx);
      setEndIdx(endIdx);
    } else {
      setTextIdx(0);
      setEndIdx(0);
    }
  }, []);

  const onClick = () => {
    if (typeof text === 'string' || textIdx >= endIdx) {
      const txt = getNextText();
      setText(txt);
      if (Array.isArray(txt)) {
        const { startIdx, endIdx } = getStartAndEndIdx(txt);
        setTextIdx(startIdx);
        setEndIdx(endIdx);
      } else {
        setTextIdx(0);
        setEndIdx(0);
      }
    } else {
      setTextIdx((n) => n + 1);
    }
  };

  const displayValue = typeof text === 'string' ? text : text[textIdx];

  return (
    <div className="widgetTextOuter" onClick={onClick}>
      <RenderText {...props} text={displayValue} key={displayValue} />
    </div>
  );
};
Text.widgetName = 'Text';
Text.sizes = [
  [2, 2],
  [2, 3],
  [2, 4],
  [2, 6],
  [3, 3],
  [3, 4],
  [3, 5],
  [4, 5],
];
