export default [
  "l'immagine non si crea, l' immagine si accoglie, ",
  "l'immagine è immanente e interstiziale, l'immagine non si cattura, ",
  "l'immagine si dá,  È , e permane chiamando a sè altre immagini, ",
  "le immagini, che nella collettivitá dell'invenzione aspirano solo ",
  [
    'a mostrarsi per esistere: a mostrarsi nel senso di scoprirsi, di svelarsi.',
    'Ascoltando queste musiche penso sempre a come e a cosa ',
    'vuol dire riflettere sulle possibilitá immaginative, ',
    'a cosa vuol dire fare esperienza attraverso la speculazione riflessiva, ',
    "il sogno, l'immagine (o l'immaginazione), la fantasia.  ",
    'Penso quindi a come tutto ció voglia dire essenzialmente che ogni cosa che sappiamo, ',
    'sentiamo, ascoltiamo e che ogni enuciato ha una base fantastica, ',
  ],
  'deriva cioè da immagini. ',
  ' la formazione di una immagine fantastica: un gesto, una figura.  ',
  [
    "Cosí l'Io dá credito alle immagini nel momento in cui ci sentiamo agiti dall'immaginazione: ",
    'quando ascoltando ci ascoltiamo, e in questa invasione di immagini, ',
    'in mezzo ai continui spassi e agli svariati divertimenti, ',
    'le ore, i giorni, le settimane, passavano come tanti baleni.',
  ],
  'una sorta di universo policentrico ',
  'in cui veritá coincide con veridicitá: ',
  ' perché la proprietá nel mondo delle ',
  ' idee non solo non ha senso ma davvero non esiste.',
  [
    'La comunicazione musicale ',
    'esiste  ad un livello puramente emozionale, ',
    "l'emozionalitá è immanente,",
    "l'espressione è definitivamente legata all'istante,",
    "l'istante è imprescindibilmente trascendente.",
  ],
  [
    'Seguo il segno che la mano imprime sul foglio,',
    'tutto ciò che si muove e non ha nome.',
    'Scrivo per quella parte di me che in qualsiasi',
  ],
  'non squarci la terra, nel tempo che si dilata',
];
