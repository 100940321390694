import { useEffect, useRef } from 'react';

/** provides access to component's mounting status - true or false.
 * - Useful for preventing async stuff from trying to create effects in
 * an unmounted cmpt.
 */
const useIsMounted = () => {
  const isMountedRef = useRef(false);

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return isMountedRef;
};

export default useIsMounted;
