import { Subscriptions } from '../subscribeController';
import { SequenceValue } from '../types';
import SongController, { SONG_FILTERS } from './Song';

export const songSubscriptions: Subscriptions<SongController> = {
  oneShotSequence: (song, v) => {
    const value = v as SequenceValue;
    // SequenceValue row/columns are zero-indexed
    if (value.row < 4 && value.column < 5) {
      song.oneShotSequence[value.row][value.column] = value.state;
    }
  },
  oneShotSequenceBeatLength: (song, beatLength) => {
    song.oneShotSequenceBeatLength = beatLength as number;
  },
  oneShotSequenceReverse: (song, val) => {
    song.oneShotSequenceReverse = Boolean(val);
  },
  loopSequenceBeatLength: (song, beatLength) => {
    song.loopSequenceBeatLength = beatLength as number;
  },
  loopSequenceReverse: (song, val) => {
    song.loopSequenceReverse = Boolean(val);
  },
  radioEffect: (song) => {
    const ramp = 0.01;
    song.nodes.HP.Q.rampTo(SONG_FILTERS.RADIO_EFFECT_HP_Q, ramp);
    song.nodes.HP.frequency.rampTo(SONG_FILTERS.RADIO_EFFECT_HP_FREQ, ramp);
    song.nodes.LP.Q.rampTo(SONG_FILTERS.RADIO_EFFECT_LP_Q, ramp);
    song.nodes.LP.frequency.rampTo(SONG_FILTERS.RADIO_EFFECT_LP_FREQ, ramp);

    song.setTimeout(() => {
      song.nodes.HP.Q.rampTo(SONG_FILTERS.HP_Q, ramp);
      song.nodes.HP.frequency.rampTo(SONG_FILTERS.HP_FREQ, ramp);
      song.nodes.LP.Q.rampTo(SONG_FILTERS.LP_Q, ramp);
      song.nodes.LP.frequency.rampTo(SONG_FILTERS.LP_FREQ, ramp);
    }, 2000);
  },
};
