import prng from '../classes/PRNG';
import SETTINGS from '../globals/settings';
import { BoxSize } from '../types/box';
import widgets from '../widgets';
import { PlaceHolder } from '../widgets/PlaceHolder';

const getWidgetsOfSize = (size: BoxSize) =>
  widgets
    .filter(
      (widget) =>
        !(
          SETTINGS.SONG.BLOCK_WIDGETS &&
          SETTINGS.SONG.BLOCK_WIDGETS.includes(widget.widgetName)
        ),
    )
    .filter((widget) => {
      const sizes = Array.isArray(widget.sizes) ? widget.sizes : widget.sizes();
      return sizes.some((s) => s[0] === size.rows && s[1] === size.columns);
    });

export const checkIfWidgetOfSizeExists = (size: BoxSize) => {
  const withSize = getWidgetsOfSize(size);
  return withSize.length > 0;
};

export const getWidgetOfSize = (size: BoxSize) => {
  const withSize = getWidgetsOfSize(size);
  if (withSize.length === 0) {
    return PlaceHolder;
  }
  return withSize[Math.floor(prng.float() * withSize.length)];
};
