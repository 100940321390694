import { Param } from '../types';

export const oneShotInitialVolume = -1.6;
export const oneShotInitialSequenceRetrigger = false;
export const getOneShotParams = (
  oneShotName: string,
  initialPan: number,
  initialDelayTime: number,
  initialDelayOn: boolean,
): Param[] => [
  {
    type: 'scalar',
    name: `${oneShotName}_volume`,
    min: -7.5,
    max: 0,
    val: oneShotInitialVolume,
  },
  {
    type: 'scalar',
    name: `${oneShotName}_speedExponent`,
    min: -0.7,
    max: 0.7,
    val: 0,
  },
  {
    type: 'boolean',
    name: `${oneShotName}_sequencerRetrigger`,
    val: oneShotInitialSequenceRetrigger,
  },
  {
    type: 'scalar',
    name: `${oneShotName}_pan`,
    min: -1,
    max: 1,
    val: initialPan,
  },
  {
    type: 'scalar',
    name: `${oneShotName}_delayTime`,
    min: 0.05,
    max: 0.6,
    val: initialDelayTime,
  },
  {
    type: 'boolean',
    name: `${oneShotName}_delayOn`,
    val: initialDelayOn,
  },
];
