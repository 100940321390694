import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import useIsMounted from '../../hooks/useIsMounted';
import { AppEventHandler } from '../../types/events';
import { ContextAppState } from '../ContextAppState';
import { ContextLoadingMusic } from '../ContextLoadingMusic';
import { ContextParams } from '../ContextParams';
import { ContextSwapManager } from '../ContextSwapManager';

interface ContextAppEventsValue {
  onAppEvent: AppEventHandler;
}

export const ContextAppEvents = createContext<ContextAppEventsValue>({
  onAppEvent: () => {
    return;
  },
});

const ProviderContextAppEvents: React.FunctionComponent<{}> = ({
  children,
}) => {
  const { onAppEvent: onAppEventParams } = useContext(ContextParams);
  const { onAppEvent: onAppEventSwapManager } = useContext(ContextSwapManager);
  const { onAppEvent: onAppEventAppState } = useContext(ContextAppState);
  const { onAppEvent: onAppEventLoadingMusic } = useContext(
    ContextLoadingMusic,
  );

  const isMounted = useIsMounted();

  const onAppEvent: AppEventHandler = useCallback(
    (event) => {
      onAppEventParams(event);
      onAppEventSwapManager(event);
      onAppEventAppState(event);
      onAppEventLoadingMusic(event);
    },
    [
      onAppEventAppState,
      onAppEventLoadingMusic,
      onAppEventParams,
      onAppEventSwapManager,
    ],
  );

  useEffect(() => {
    const onBlur = () => {
      if (isMounted.current) {
        onAppEvent({ name: 'tabBlur' });
      }
    };
    const onFocus = () => {
      if (isMounted.current) {
        onAppEvent({ name: 'tabFocus' });
      }
    };
    window.addEventListener('blur', onBlur);
    window.addEventListener('focus', onFocus);

    return () => {
      window.removeEventListener('blur', onBlur);
      window.removeEventListener('focus', onFocus);
    };
  }, [isMounted, onAppEvent]);
  const contextValue: ContextAppEventsValue = useMemo(() => {
    return {
      onAppEvent,
    };
  }, [onAppEvent]);

  return (
    <ContextAppEvents.Provider value={contextValue}>
      {children}
    </ContextAppEvents.Provider>
  );
};

export default ProviderContextAppEvents;
