import React, { useCallback, useEffect, useMemo, useState } from 'react';
import prng from '../../classes/PRNG';
import { useSequenceBeat } from '../../hooks/useSequenceBeat';
import { useWidgetColors } from '../../hooks/useWidgetColors';
import { WidgetComponent } from '../../types/widget';

const STROKE_MAINBEATS = 0.8;
const STROKE_PATHS = 0.2;
const STROKE_EXTRAS = 0.6;
export const BeatVisual: WidgetComponent = (props) => {
  const { width, height } = props;
  const [offset, setOffset] = useState(0);
  const [offsetB, setOffsetB] = useState(0);
  const { beatNumber: beatA } = useSequenceBeat('currentLoopBeat');
  const { beatNumber: beatB } = useSequenceBeat('currentOneShotBeat');

  const weirdBeatC = (Math.pow(beatA + 3, beatB) + offset) % 5;
  const weirdBeatD = (Math.pow(beatB + 2, beatB + 1) + offsetB) % 5;

  const { accentColor, fillColor } = useWidgetColors();
  const rNormal = (props.height + props.width) * 0.005;
  const rEmphasis = (props.height + props.width) * 0.01;

  useEffect(() => {
    setOffset((x) => x + 1);
    setOffsetB((x) => x + 2);
  }, [beatA, beatB]);

  const dotPositions = useMemo(
    () => ({
      beatA: new Array(5).fill(0).map((_, i) => ({
        x: prng.float() * width * 0.2 + width * 0.19 * i,
        y: prng.float() * height * 0.5,
      })),
      beatB: new Array(5).fill(0).map((_, i) => ({
        x: prng.float() * width * 0.2 + width * 0.19 * i,
        y: prng.float() * height * 0.55 + height * 0.4,
      })),
    }),
    [height, width],
  );

  const getBeatAPosition = useCallback(
    (beat: number) => {
      return dotPositions.beatA[beat] || { x: 0, y: 0 };
    },
    [dotPositions],
  );

  const getBeatBPosition = useCallback(
    (beat: number) => {
      return dotPositions.beatB[beat] || { x: 0, y: 0 };
    },
    [dotPositions],
  );

  const { x: xFrom, y: yFrom } = getBeatAPosition(beatA);
  const { x: xTo, y: yTo } = getBeatBPosition(beatB);
  const { x: xToC, y: yToC } = getBeatBPosition(weirdBeatC);
  const { x: xToD, y: yToD } = getBeatAPosition(weirdBeatD);
  return (
    <>
      <svg
        height={`${props.height}px`}
        width={`${props.width}px`}
        className="BeatVisualOuter"
      >
        {dotPositions.beatA.slice(1).map(({ x, y }, n) => {
          const from = getBeatAPosition(n);
          if (!from) return null;
          return (
            <line
              key={n}
              x1={from.x}
              y1={from.y}
              x2={x}
              y2={y}
              style={{
                stroke: fillColor,
                strokeWidth: rNormal * STROKE_PATHS,
              }}
            />
          );
        })}

        {dotPositions.beatB.slice(1).map(({ x, y }, n) => {
          const from = getBeatBPosition(n);
          if (!from) return null;
          return (
            <line
              key={n}
              x1={from.x}
              y1={from.y}
              x2={x}
              y2={y}
              style={{
                stroke: fillColor,
                strokeWidth: rNormal * STROKE_PATHS,
              }}
            />
          );
        })}

        <line
          x1={xFrom}
          y1={yFrom}
          x2={xToC}
          y2={yToC}
          style={{
            stroke: fillColor,
            strokeWidth: rNormal * STROKE_EXTRAS,
          }}
        />
        <line
          x1={xToC}
          y1={yToC}
          x2={xTo}
          y2={yTo}
          style={{
            stroke: fillColor,
            strokeWidth: rNormal * STROKE_EXTRAS,
          }}
        />
        <line
          x1={xToC}
          y1={yToC}
          x2={xToD}
          y2={yToD}
          style={{
            stroke: fillColor,
            strokeWidth: rNormal * STROKE_EXTRAS,
          }}
        />
        <line
          x1={xFrom}
          y1={yFrom}
          x2={xToD}
          y2={yToD}
          style={{
            stroke: fillColor,
            strokeWidth: rNormal * STROKE_EXTRAS,
          }}
        />
        <line
          x1={xTo}
          y1={yTo}
          x2={xToD}
          y2={yToD}
          style={{
            stroke: fillColor,
            strokeWidth: rNormal * STROKE_EXTRAS,
          }}
        />
        <line
          x1={xFrom}
          y1={yFrom}
          x2={xTo}
          y2={yTo}
          style={{
            stroke: accentColor,
            strokeWidth: rNormal * STROKE_MAINBEATS,
          }}
        />
        {dotPositions.beatA.map(({ x, y }, i) => {
          return (
            <circle
              key={i}
              cx={x}
              cy={y}
              r={beatA === i ? rEmphasis : rNormal}
              fill={beatA === i ? accentColor : fillColor}
            />
          );
        })}
        {dotPositions.beatB.map(({ x, y }, i) => {
          return (
            <circle
              key={i}
              cx={x}
              cy={y}
              r={beatB === i ? rEmphasis : rNormal}
              fill={beatB === i ? accentColor : fillColor}
            />
          );
        })}
      </svg>
    </>
  );
};

BeatVisual.widgetName = 'BeatVisual';
BeatVisual.sizes = [
  [3, 2],
  [2, 3],
];
