import SETTINGS from '../../../globals/settings';
import { Param } from '../types';

export const songInitialParamValues = {
  oneShotSequenceBeatLength: 2,
  loopSequenceBeatLength: 1.5,
};
export const getSongParams = (
  songName: string,
  oneShotSequence: boolean[][],
): Param[] => {
  const params: Param[] = [
    {
      type: 'scalar',
      name: `${songName}_currentOneShotBeat`,
      min: 0,
      max: 4,
      val: 0,
      maxRandomSubscribers: 0,
    },
    {
      type: 'scalar',
      name: `${songName}_currentLoopBeat`,
      min: 0,
      max: 4,
      val: 0,
      maxRandomSubscribers: 0,
    },
    {
      type: 'trigger',
      name: `${songName}_radioEffect`,
      val: 0,
    },
  ];

  if (SETTINGS.SONG.LOOPS > 0) {
    // add loop stuff
    params.push({
      type: 'scalar',
      name: `${songName}_loopSequenceBeatLength`,
      min: 0.25,
      max: 3,
      val: songInitialParamValues.loopSequenceBeatLength,
    });
    params.push({
      type: 'boolean',
      name: `${songName}_loopSequenceReverse`,
      val: false,
    });
  }
  if (SETTINGS.SONG.ONE_SHOTS > 0) {
    // add one-shot sequence stuff
    params.push({
      type: 'sequence',
      name: `${songName}_oneShotSequence`,
      rows: SETTINGS.SONG.ONE_SHOTS,
      columns: 5,
      val: oneShotSequence,
    });
    params.push({
      type: 'scalar',
      name: `${songName}_oneShotSequenceBeatLength`,
      min: 0.5,
      max: 4,
      val: songInitialParamValues.oneShotSequenceBeatLength,
    });
    params.push({
      type: 'boolean',
      name: `${songName}_oneShotSequenceReverse`,
      val: false,
    });
  }

  return params;
};
