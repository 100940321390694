import React, { useContext, useEffect, useState } from 'react';
import { animalImageUrls } from '../../globals/animalImageUrls';
import assetPreloader from '../../globals/assetPreloader';
import { songManifests } from '../../globals/songManifests';
import { ContextAppState } from '../ContextAppState';
import './styles.css';

export function Loading() {
  const {
    state: { songManifest },
  } = useContext(ContextAppState);

  const [imageUrl, setImageUrl] = useState('');
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!songManifest) return;

    const thisSongIdx = songManifests.findIndex(
      (s) => songManifest.name === s.name,
    );
    const animalImg = animalImageUrls[thisSongIdx % animalImageUrls.length];
    const url = assetPreloader.getImage(animalImg);
    setImageUrl(url);
  }, [songManifest]);

  return (
    <div className="LoadingOuter">
      <img
        src={imageUrl}
        alt="mysterious geometric animal face"
        onLoad={() => setVisible(true)}
        className="LoadingImage"
        style={{
          opacity: visible ? 1 : 0,
        }}
      />
      <div className="LoadingBar">
        <div className="LoadingBarInner" />
      </div>
    </div>
  );
}
