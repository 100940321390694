import * as Tone from 'tone';
import { unmute } from './IOSFix.js';

let _audioContextStarted = false;

const handler = () => {
  if (!_audioContextStarted) {
    if (Tone.context.state !== 'running') {
      // attempt to unmute IOS
      try {
        unmute(Tone.getContext().rawContext);
      } catch (eE) {
        console.error(eE);
      }
      Tone.start().then(() => {
        _audioContextStarted = true;
      });
      document.removeEventListener('click', handler);
      document.removeEventListener('touchstart', handler);
      document.removeEventListener('touchend', handler);
      document.removeEventListener('keydown', handler);
      document.removeEventListener('mousedown', handler);
    }
  }
};
export const initializeAudioContextOnGesture = () => {
  Tone.setContext(new Tone.Context({ latencyHint: 'balanced' }));
  document.addEventListener('click', handler);
  document.addEventListener('touchstart', handler);
  document.addEventListener('touchend', handler);
  document.addEventListener('keydown', handler);
  document.addEventListener('mousedown', handler);
};
