import prng from '../classes/PRNG';
import { FileManifest, SongManifest } from '../types/fileManifest';
import { shuffleArray } from '../utils';
import { animalImageUrls } from './animalImageUrls';
import assetPreloader from './assetPreloader';
import fileManifest from './fileManifest.json';

const songs = (fileManifest as FileManifest).songs;
export const songManifests: SongManifest[] = shuffleArray(
  songs.map((song) => ({
    ...song,
    audio: {
      loops: shuffleArray(song.audio.loops),
      oneshots: shuffleArray(song.audio.oneshots),
    },
  })),
);

export const getNextSongOptionAndImage = (curSongName: string) => {
  const thisSongIdx = songManifests.findIndex((s) => curSongName === s.name);
  const otherSongs = songManifests.filter((m, i) => {
    const isSameSong = m.name === curSongName;
    const hasSameFace =
      thisSongIdx % animalImageUrls.length === i % animalImageUrls.length;
    return !isSameSong && !hasSameFace;
  });

  const target = otherSongs[Math.floor(prng.float() * otherSongs.length)];

  const targetIdx = songManifests.findIndex((s) => target.name === s.name);
  const animalImg = animalImageUrls[targetIdx % animalImageUrls.length];

  const url = assetPreloader.getImage(animalImg);
  return {
    song: target,
    imageUrl: url,
  };
};
